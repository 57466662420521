import Vue from "vue";
import Vuex from "vuex";
import user from "./user.module";
import appState from "./app.module";
import category from "./category.module";
import cart from "./cart.module";
import productVariant from "./productVariant.module";
import product from "./product.module";
import content from "./content.module";
import pricing from "./pricing.module";
import exam from "./exam.module";
import form from "./form.module";
import support from "./support.module";
import bulkFileUploading from "./bulkFileUploading.module";
import quiz from "./quiz.module";
import assignment from "./assignment.module";
import certificate from "./certificate.module";

Vue.use(Vuex);
//Create store
export default new Vuex.Store({
  modules: {
    user,
    appState,
    category,
    cart,
    product,
    productVariant,
    content,
    pricing,
    exam,
    form,
	  support,
    bulkFileUploading,
    quiz,
    assignment,
    certificate,
  },
});
