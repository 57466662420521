<template>
    <div class="certificates-container" ref="certificatesContainer" @scroll="onScroll">
        <div class="header-actions">
            <div class="search-filter-container">
                <div class="search-container">
                    <input type="text" placeholder="Search user by ID, name, email, or phone number" v-model="searchQuery"
                        @key-down="reloadCertificates" class="search-input" />
                </div>

                <div class="actions-group">
                    <button class="filter-btn" @click="reloadCertificates">
                        Search
                        <i class="fas fa-search certificatesearch-icon"></i>
                    </button>
                    <div class="filter-dropdown" ref="filterRef">
                        <button class="filter-btn" @click="showFilterDropdown = !showFilterDropdown">
                            FILTER
                            <i :class="['fas', 'fa-chevron-down', { 'rotate-180': showFilterDropdown }]" />
                        </button>
                        <div v-if="showFilterDropdown" class="custom-dropdown-menu">
                            <div class="filter-option">
                                <label>
                                    <input type="radio" v-model="filters.sortOrder" value="recent" />
                                    Recent
                                </label>
                            </div>
                            <div class="filter-option">
                                <label>
                                    <input type="radio" v-model="filters.sortOrder" value="oldest" />
                                    Oldest
                                </label>
                            </div>
                        </div>

                    </div>

                    <div class="export-dropdown" ref="exportRef">
                        <button class="export-btn" @click="showExportDropdown = !showExportDropdown">
                            EXPORT AS
                            <i :class="['fas', 'fa-chevron-down', { 'rotate-180': showExportDropdown }]" />
                        </button>
                        <div v-if="showExportDropdown" class="custom-dropdown-menu" style="display: flex;">
                            <!-- <button class="export-option" disabled="true" @click="exportAs('pdf')">PDF</button> -->
                            <button class="export-option" @click="exportAs('excel')">Excel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="table-container">
            <div v-if="loading" class="loading-container">
                <div class="spinner"></div>
                <p class="loading-text">Loading certificates, please wait...</p>
            </div>

            <div v-else-if="!loading && filteredCertificates.length === 0" class="no-data-container">
                <p class="no-data-text">No certificates found. </p>
            </div>

            <table v-else>
                <thead>
                    <tr>
                        <th class="checkbox-column">
                            <input type="checkbox" :checked="selectAll" @change="toggleSelectAll" class="checkbox" />
                        </th>
                        <th>ID</th>
                        <th>Certificate Name</th>
                        <th>Student ID</th>
                        <th>Student Name</th>
                        <th>Issued</th>
                        <th>Valid Until</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="certificate in filteredCertificates" :key="certificate.userCertificate.id">
                        <td class="checkbox-column">
                            <input type="checkbox" v-model="certificate.selected" class="checkbox" />
                        </td>
                        <td>{{ certificate.certificate && certificate.userCertificate.id || '-' }}</td>
                        <td>{{ certificate.certificate && certificate.certificate.certificateName || 'N/A' }}</td>

                        <td>{{ certificate.user.id }}</td>
                        <td>{{ getFullName(certificate.user) }}</td>
                        <td>{{ formatDate(certificate.userCertificate.issueDate) }}</td>
                        <td>{{ formatDate(certificate.userCertificate.validUntil) || 'N/A' }}</td>

                        <td>
                            <div class="actions-dropdown" :ref="'actions' + certificate.userCertificate.id">
                                <button class="actions-btn" @click="toggleActionMenu(certificate.userCertificate.id)">
                                    ACTIONS
                                    <i class="fas fa-chevron-down" />
                                </button>
                                <div v-if="activeActionMenu === certificate.userCertificate.id"
                                    class="custom-dropdown-menu">
                                    <button class="action-option" @click="downloadCertificate(certificate)">
                                        <i class="fas fa-download" />
                                        Download
                                    </button>
                                    <button class="action-option text-red-500" @click="revokeCertificate(certificate)">
                                        <i class="fas fa-ban" />
                                        Revoke
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { EntityType } from "../../../constants/entity";

export default {
    data() {
        return {
            searchQuery: "",
            showFilterDropdown: false,
            showExportDropdown: false,
            activeActionMenu: null,
            filters: { sortOrder: 'recent' },
            loading: false,
        };
    },
    computed: {
        ...mapState("certificate", ["userCertificates"]),
        ...mapGetters("certificate", ["isReloading"]),

        filteredCertificates() {
            let result = [...this.userCertificates];
            if (this.searchQuery) {
                result = result.filter((cert) =>
                    `${cert.user.id} ${cert.user.firstName} ${cert.user.lastName}`
                        .toLowerCase()
                        .includes(this.searchQuery.toLowerCase())
                    || `${cert.certificate?.id || ''} ${cert.certificate?.certificateName || ''}`
                        .toLowerCase()
                        .includes(this.searchQuery.toLowerCase())
                    || `${cert.userCertificate.id}`
                        .toLowerCase()
                        .includes(this.searchQuery.toLowerCase())
                );
            }
            // if (this.filters.sortOrder === 'recent') {
            //     result.sort((a, b) => new Date(b.userCertificate.createdAt) - new Date(a.userCertificate.createdAt));
            // } else if (this.filters.sortOrder === 'oldest') {
            //     result.sort((a, b) => new Date(a.userCertificate.createdAt) - new Date(b.userCertificate.createdAt));
            // }

            return result;
        },

        selectAll: {
            get() {
                return this.filteredCertificates.every((c) => c.selected);
            },
            set(value) {
                this.filteredCertificates.forEach((c) => (c.selected = value));
            },
        },
    },
    methods: {
        ...mapActions("certificate", ["fetchUserCertificates", "loadMoreUserCertificates", "revokeUserCertificate", "exportUserCertificates"]),

        async reloadCertificates() {
            if (this.isReloading) return;

            this.loading = true;

            await this.fetchUserCertificates({
                entityID: this.$route.params.id, entityType: EntityType.PRODUCTVARIANT,
                searchQuery: this.searchQuery, isNewestFirst: this.filters.sortOrder === 'recent',
                reload: true
            });
            this.loading = false;
            this.resetScroll();
        },
        async onScroll() {
            const container = this.$refs.certificatesContainer;

            if (container.scrollTop + container.clientHeight >= container.scrollHeight - 50) {
                await this.loadMoreUserCertificates({
                    entityID: this.$route.params.id,
                    entityType: EntityType.PRODUCTVARIANT,
                    searchQuery: this.searchQuery,
                    isNewestFirst: this.filters.sortOrder === 'recent',
                });
            }
        },
        toggleActionMenu(id) {
            this.activeActionMenu = this.activeActionMenu === id ? null : id;
        },
        toggleSelectAll() {
            const newValue = !this.selectAll;
            this.filteredCertificates.forEach((c) => (c.selected = newValue));
        },
        downloadCertificate(certificate) {
            const link = document.createElement("a");
            link.href = certificate.userCertificate.certificateURL;
            link.download = certificate.certificate?.certificateName || "certificate";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },

        revokeCertificate(certificate) {
            console.log("Revoking certificate:", certificate.userCertificate.id);
            this.revokeUserCertificate(certificate.userCertificate.id)

        },
        exportAs(format) {
            if (format === "excel") {
                this.exportUserCertificates({entityId: this.$route.params.id, entityType: EntityType.PRODUCTVARIANT })
            } else {
                console.log(`Unsupported export format: ${format}`);
            }
        },

        // exportAsExcel() {
        //     const data = this.filteredCertificates.map((certificate) => ({
        //         ID: certificate.userCertificate.id || "-",
        //         "Certificate ID": certificate?.certificate?.id,
        //         "Certificate Name": certificate.certificate?.certificateName || "N/A",
        //         "Student ID": certificate.user.id,
        //         "Student Name": this.getFullName(certificate.user),
        //         "Certificate URL": certificate.userCertificate?.certificateURL,
        //         Issued: this.formatDate(certificate.userCertificate.issueDate),
        //         "Valid Until": this.formatDate(certificate.userCertificate.validUntil) || "N/A",
        //     }));

        //     const worksheet = XLSX.utils.json_to_sheet(data);
        //     const workbook = XLSX.utils.book_new();
        //     XLSX.utils.book_append_sheet(workbook, worksheet, "Certificates");

        //     const fileName = `certificates_${new Date().toISOString().slice(0, 10)}.xlsx`;
        //     XLSX.writeFile(workbook, fileName);
        // },
        formatDate(date) {
            return date ? new Date(date).toLocaleDateString() : "";
        },
        getFullName(user) {
            return user ? `${user.firstName || ""} ${user.lastName || ""}`.trim() : "";
        },
        resetScroll() {
            this.$nextTick(() => {
                this.$refs.certificatesContainer.scrollTop = 0;
            });
        },
    },
    watch: {
    'filters.sortOrder': function (newSortOrder, oldSortOrder) {
      if (newSortOrder !== oldSortOrder) {
        this.reloadCertificates()
      }
    },
  },

    created() {
        this.reloadCertificates()
    },
};
</script>
  
<style scoped>
.certificates-container {
    position: relative;
    height: calc(100svh - var(--header-height) - 65px);
    padding: 2rem;
    overflow: auto;
}

.header-actions {
    margin-bottom: 2rem;
}

.search-filter-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.search-container {
    flex-grow: 1;
}

.search-input {
    width: 100%;
    padding: 0.75rem 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    font-size: 0.875rem;
}

.actions-group {
    display: flex;
    gap: 1rem;
}

.filter-btn,
.export-btn,
.actions-btn {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    background-color: white;
    font-size: 0.875rem;
    cursor: pointer;
}

.table-container {
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    margin-bottom: 1rem;
}

table {
    width: 100%;
    /* Make the table take all available space */
    border-collapse: collapse;
    table-layout: auto;
    /* Allow cells to adjust width dynamically */
}

th,
td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #e5e7eb;
}

th {
    background-color: #f9fafb;
    font-weight: 500;
    color: #374151;
}

.checkbox-column {
    width: 48px;
}

.checkbox {
    width: 1rem;
    height: 1rem;
    border-radius: 0.25rem;
    border: 1px solid #e5e7eb;
}

.actions-dropdown {
    position: relative;
}

.custom-dropdown-menu {
    position: absolute;
    left: 0;
    top: 100%;
    margin-top: 0.25rem;
    background-color: white;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 999;
    min-width: 120px;
    overflow: visible;
}

.action-option {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    padding: 0.5rem 1rem;
    text-align: left;
    border: none;
    background: none;
    cursor: pointer;
}

.action-option:hover {
    background-color: #f9fafb;
}

.filter-option,
.export-option {
    flex-grow: 1;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.export-dropdown,
.filter-dropdown {
    position: relative;
}

.filter-option:hover,
.export-option:hover {
    background-color: #f9fafb;
}

.pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;
    font-size: 0.875rem;
}

.rows-per-page select {
    margin-left: 0.5rem;
    padding: 0.25rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.25rem;
}

.pagination-controls {
    display: flex;
    gap: 0.5rem;
}

.pagination-btn {
    padding: 0.25rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.25rem;
    background: white;
    cursor: pointer;
}

.pagination-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Transitions */
.rotate-180 {
    transform: rotate(180deg);
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    color: #555;
}

.spinner {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-top-color: black;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.loading-text {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
}

.no-data-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    color: #333;
    text-align: center;
    margin-top: 10px;
}

.no-data-icon {
    font-size: 40px;
    margin-bottom: 10px;
}

.no-data-text {
    font-size: 16px;
    font-weight: 500;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
</style>