export class CertificateType {
    static EXAM = "Exam";
    static COMPLETION = "Completion";
  
    static getCertificateType(certificateType) {
      const validTypes = {
        [CertificateType.EXAM.toLowerCase()]: CertificateType.EXAM,
        [CertificateType.COMPLETION.toLowerCase()]: CertificateType.COMPLETION
      };
  
      return validTypes[certificateType.toLowerCase()] || null;
    }
  }

class CertificateCriteriaType {
    static EXAM_PASSING = "ExamPassing";
    static COMPLETION = "Completion";
    static PERCENTAGE_COMPLETION = "PercentageCompletion";
  
    static getCriteriaType(type) {
      const validTypes = {
        [CertificateCriteriaType.EXAM_PASSING.toLowerCase()]: CertificateCriteriaType.EXAM_PASSING,
        [CertificateCriteriaType.COMPLETION.toLowerCase()]: CertificateCriteriaType.COMPLETION,
        [CertificateCriteriaType.PERCENTAGE_COMPLETION.toLowerCase()]: CertificateCriteriaType.PERCENTAGE_COMPLETION
      };
  
      return validTypes[type.toLowerCase()] || null;
    }
  }
  
  export { CertificateCriteriaType };
  