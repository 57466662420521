import store from "../store/index";
import ApiHelpers from "../helpers/ApiHelpers";
import {
  CERTIFICATE_API,
  CERTIFICATE_USERS_API,
 
} from "../constants/urls";

export const certificateService = {
  fetchCertificateByID,
  fetchCertificates,
  addCertificate,
  updateCertificate,
  deleteCertificate,
  fetchCurrentUserCertificates,
  fetchUserCertificates,
  revokeUserCertificate,
  exportUserCertificates,
};

// Fetch a certificate by ID
async function fetchCertificateByID(id) {
  try {
    const response = await ApiHelpers.makeAPICall(
      `${store.state.appState.apiURL}${CERTIFICATE_API}/${id}`,
      "GET",
      null,
      false
    );
 
    return await handleResponse(response);
  } catch (error) {
    console.error("Error fetching certificate by ID:", error);
    throw error;
  }
}

// Fetch all certificates with optional filters
async function fetchCertificates(entityId = null, entityType = null) {
  try {
    const url = `${store.state.appState.apiURL}${CERTIFICATE_API}?entityId=${entityId}&entityType=${entityType}`;
    const response = await ApiHelpers.makeAPICall(url, "GET", null, false);
    return await handleResponse(response);
  } catch (error) {
    console.error("Error fetching certificates:", error);
    throw error;
  }
}

// Add a new certificate
async function addCertificate(certificateData) {
  try {
    const response = await ApiHelpers.makeAPICall(
      `${store.state.appState.apiURL}${CERTIFICATE_API}`,
      "POST",
      JSON.stringify(certificateData),
      true
    );
    return await handleResponse(response);
  } catch (error) {
    console.error("Error adding certificate:", error);
    throw error;
  }
}

// Update an existing certificate
async function updateCertificate(certificateData) {
  try {
    const response = await ApiHelpers.makeAPICall(
      `${store.state.appState.apiURL}${CERTIFICATE_API}`,
      "PUT",
      JSON.stringify(certificateData),
      true
    );
    return await handleResponse(response);
  } catch (error) {
    console.error("Error updating certificate:", error);
    throw error;
  }
}

// Delete a certificate by ID
async function deleteCertificate(id) {
  try {
    const response = await ApiHelpers.makeAPICall(
      `${store.state.appState.apiURL}${CERTIFICATE_API}/${id}`,
      "DELETE",
      null,
      false
    );

    return await handleResponse(response);
  } catch (error) {
    console.error("Error deleting certificate:", error);
    throw error;
  }
}

// Fetch all certificates with optional filters
async function fetchCurrentUserCertificates() {
  try {
    const url = `${store.state.appState.apiURL}${CERTIFICATE_API}/me`;
    const response = await ApiHelpers.makeAPICall(url, "GET", null, false);
    return await handleResponse(response);
  } catch (error) {
    console.error("Error fetching certificates:", error);
    throw error;
  }
}

// Fetch certificates for a specific user based on entity ID and type
async function fetchUserCertificates({ entityID = null, entityType = null, nextPageToken = null, searchQuery = null, isNewestFirst = true, pageSize = 25 } = {}) {
    try {
      const queryParams = new URLSearchParams();
      if (entityID) queryParams.append("entityID", entityID);
      if (entityType) queryParams.append("entityType", entityType);
      if (searchQuery) queryParams.append("searchQuery", searchQuery);
      if (nextPageToken) queryParams.append("nextPageToken", nextPageToken);
     
      queryParams.append("isNewestFirst", isNewestFirst);
      queryParams.append("pageSize", pageSize);

      const apiUrl = `${store.state.appState.apiURL}${CERTIFICATE_USERS_API}?${queryParams.toString()}`;
      const response = await ApiHelpers.makeAPICall(apiUrl, 'GET', null, false);
      const responseData = await response?.json()
  
      return responseData;
    } catch (error) {
      console.error('Error fetching notifications:', error);
      return null;
    }
  }

  async function revokeUserCertificate(id) {
    try {
      const response = await ApiHelpers.makeAPICall(
        `${store.state.appState.apiURL}${CERTIFICATE_USERS_API}/revoke/${id}`,
        "POST",
        null,
        true
      );
      return await handleResponse(response);
    } catch (error) {
      console.error("Error updating certificate:", error);
      throw error;
    }
  }

  async function exportUserCertificates(entityId, entityType) {
    try {
        const url = `${store.state.appState.apiURL}/certificate/export?entityId=${entityId}&entityType=${entityType}`;

        const response = await ApiHelpers.makeAPICall(url,"GET",null, false);

        if (response.status === 200) {
            const blob = await response.blob();
            const fileName = `certificates_${new Date().toISOString().slice(0, 10)}.csv`; 
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName; 
            document.body.appendChild(link);
            link.click(); 
            document.body.removeChild(link);
        } else {
            console.error("Failed to export certificates:", response.status);
            throw new Error("Failed to export certificates.");
        }
    } catch (error) {
        console.error("Error exporting certificates:", error);
        throw error;
    }
}

  

async function handleResponse(response) {
    try {
        const text = await response.text(); 
        const body = text ? JSON.parse(text) : null; 

        if (body?.status?.toLowerCase() === 'success') {
            return body.payload;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error parsing JSON:", error);
        return null;
    }
}

