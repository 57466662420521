import { contentService } from "../services/content.service";
import Directory from "../models/content/directory";
import RecordedVideo from "../models/content/recordedVideo";
import LiveClass from "../models/content/liveClass";
import { ContentType } from "../constants/content";

import Vue from "vue";
import store from ".";

const state = {
	content: {},
	upcomingContent: [],
	loading: false,
	allRootContent: [],
	trainersByContentId: {},
	uploadingContent: false,
	copiedTab: null,
	copiedContent: null,
	copiedSection: [],
	copiedBulkContent: [],
	selectedBulkCopiedContent: [],
	isContentSectionSelectable: false,
	isChapterSelectable: false,
	contentActions: {}
};

const getters = {
	getContent: (state) => state.content,
};

const actions = {
	makeBulkChapterUnselectable({ commit }) {
		state.isChapterSelectable = false;
		state.copiedSection = [];
	},
	makeBulkChapterSelectable({ commit }) {
		state.isChapterSelectable = true;
		state.copiedSection = [];
	},
	selectedChapters({commit}) {
		if(state.copiedSection.length > 0) {
			state.isChapterSelectable = false;

			Vue.toasted.success(`${state.copiedSection.length} CHAPTERs/SECTIONs Copied`, {
				theme: "outline",
				position: "bottom-center",
				duration: 5000,
			});
		}
	},
	selectBulkChapters({ commit }, content) {
		state.copiedSection.push(content);
	},
	unselectBulkChapters({ commit }, content) {
		let index = -1;

		for (let i = 0; i < state.copiedSection.length; i++) {
			if (state.copiedSection[i].id == content.id) {
				index = i;
				break;
			}
		}

		if (index > -1) {
			state.copiedSection.splice(index, 1);
		}
	},	
	saveCopiedTab({ commit }, tab) {
		state.copiedTab = tab;
	},
	saveCopiedSection({ commit }, section) {
		state.copiedSection = [];
		state.copiedSection[0] = section;
	},
	saveCopiedContent({ commit }, content) {
		state.copiedContent = content;
	},
	saveBulkCopiedContent({ commit }, content) {
		if(content) {
			state.copiedBulkContent[0] = content;
			return;
		}
		state.copiedBulkContent = state.selectedBulkCopiedContent;

		state.isContentSectionSelectable = false;
		state.selectedBulkCopiedContent = [];
		return state.copiedBulkContent.length;
  	},
	async pasteBulkCopiedContent({ commit }, parentContentID) {
		let pastedContentSectionLength = 0;

		for (let i = 0; i < state.copiedBulkContent.length; i++) {
			let object = {
				content: state.copiedBulkContent[i],
				destinationContentID: parentContentID
			};

			let destinationParent = await contentService.copyContent(object).then(
				(res) => {
					pastedContentSectionLength++;
					var data = res?.payload;
					console.log("response", data);
					// data = new Directory(data);
					return data;
				},
				(error) => {
					Vue.toasted.error(error, {
						icon: "exclamation-triangle",
						theme: "outline",
						position: "bottom-center",
						duration: 3000,
					});
					return null;
				}
				
			);

			if (destinationParent) {
				var contentObj = {
					data: new Directory(destinationParent).children,
					parentDirectoryID: destinationParent.id,
				};
				commit("updateContentChildren", contentObj);
				commit("setLoading", false);				
			}
		}
		// return destinationParent;

		state.isContentSectionSelectable = false;
		state.selectedBulkCopiedContent = [];
		return pastedContentSectionLength;
	},
	makeBulkContentSelectionSelectable({ commit }) {
		state.isContentSectionSelectable = true;
		state.selectedBulkCopiedContent = [];
		state.copiedBulkContent = [];
	},
	makeBulkContentSelectionUnselectable({ commit }) {
		state.isContentSectionSelectable = false;
	},
	selectBulkContent({ commit }, content) {
		state.selectedBulkCopiedContent.push(content);
	},
	unselectBulkContent({ commit }, content) {
		let index = -1;

		for (let i = 0; i < state.selectedBulkCopiedContent.length; i++) {
			if (state.selectedBulkCopiedContent[i].id == content.id) {
				index = i;
				break;
			}
		}

		if (index > -1) {
			state.selectedBulkCopiedContent.splice(index, 1);
		}
	},
	copyContent: async ({ commit }, copyContentData) => {
		commit("setLoading", true);
		const destinationParent = await contentService
			.copyContent(copyContentData)
			.then(
				(res) => {
					console.log("copied data", res);
					var data = res?.payload;
					console.log("response", data);
					// data = new Directory(data);
					return data;
				},
				(error) => {
					Vue.toasted.error(error, {
						icon: "exclamation-triangle",
						theme: "outline",
						position: "bottom-center",
						duration: 3000,
					});
					console.log(error);
					commit("setLoading", false);
					return null;
				}
			);
		console.log({ destinationParent });
		// commit("setContent", destinationParent);
		if (destinationParent) {
			var contentObj = {
				data: new Directory(destinationParent).children,
				parentDirectoryID: destinationParent.id,
			};
			commit("updateContentChildren", contentObj);
			commit("setLoading", false);
		}
		return destinationParent;
	},
	getContentById: async ({ commit }, { id, extraParams = null }) => {
		// state.loading = true;
		// console.log("content type fetched: ", type);
		console.log("getContentById", id, extraParams);
		commit("setLoading", true);
		const data = await contentService.fetchContentById(id, extraParams).then(
			(content) => {
				var data = content?.payload;
				// console.log(data.type.toLowerCase());

				if (data.type.toLowerCase() === "directory") {
					return new Directory(data);
				} else if (data.type.toLowerCase() === "recordedvideos") {
					return new RecordedVideo(data);
				} else if (data.type.toLowerCase() === "liveclass") {
					return new LiveClass(data);
				} else return data;
			},
			(error) => {
				var errorMessage;
				var errorIcon = "exclamation-triangle";
				if (error.message == "122") {
					errorMessage = "You are not allowed to access this content!";
					errorIcon = "lock";
				} else if (error.message == "123") {
					errorMessage =
						"Your access is blocked, please contact your administrator/trainer for more information.";
					errorIcon = "lock";
				}
				Vue.toasted.error(errorMessage, {
					icon: errorIcon,
					theme: "outline",
					position: "bottom-center",
					singleton: true,
					duration: 3000,
				});
				console.log(error);
				//alert(error);
				commit("setLoading", false);
				return error.message;
			}
		);
		console.log("content data", data);
		if (typeof data == "object") {
			commit("setContent", data);
		}
		return data;
		// commit("setLoading", false);
	},
	getUserContentByProductVariant: async ({ commit }, { productVariantID, dataType = null }) => {
		const data = await contentService.getUserContentByProductVariant({
			productVariantID: productVariantID,
			dataType: dataType
		}).then(
			(content) => {
				var data = content?.payload;

				if (data && data.length > 0) {

					 data.forEach((val) => {
						if (val.type.toLowerCase() === ContentType.DIRECTORY.toLowerCase()) {
							let tempLevel0 = new Directory(val);

							let children = tempLevel0.children.map((val1) => {
								if (val1.type.toLowerCase() === ContentType.DIRECTORY.toLowerCase()) {

									let filteredItems = val1.children.filter((item) => {
										if (item.type !== 'RecordedVideos' || item?.externalService?.serviceProvider?.toLowerCase() != 'vss') {
											return true;
										} else if (
											item.extraProperties &&
											item.extraProperties.Status &&
											item.extraProperties.Status.toLowerCase() === 'processed'
										) {
											return true;
										}
										return false;
									});
									
									val1.children = filteredItems;

									return val1;

								}
							});

							tempLevel0.children = children

							state.content[val.id] = tempLevel0;
						}
					});

				}
				return data;
			},
			(error) => {
				var errorMessage;
				var errorIcon = "exclamation-triangle";
				Vue.toasted.error(errorMessage, {
					icon: errorIcon,
					theme: "outline",
					position: "bottom-center",
					singleton: true,
					duration: 3000,
				});
				console.log(error);
			}
		);


		if (typeof data == "object") {
			commit("setContent", data);
		}

		return data;
	},
	getAllChildContentByProductVariant: async ({ commit }, { productVariantID }) => {
		const data = await contentService.getAllChildContentByProductVariant({
			productVariantID: productVariantID,
		})
		return data;
	},
	fetchUserContentActionsByID: async ({ commit }, { productVariantID, dataType = null }) => {
		const data = await contentService.fetchUserContentActionsByID({
			productVariantID: productVariantID,
			dataType: dataType
		}).then(
			(content) => {
				var data = content?.payload;
				return data;
			},
			(error) => {
				var errorMessage;
				var errorIcon = "exclamation-triangle";
				Vue.toasted.error(errorMessage, {
					icon: errorIcon,
					theme: "outline",
					position: "bottom-center",
					singleton: true,
					duration: 3000,
				});
				console.log(error);
			}
		);

		return data;
	},
	fetchUserAllContentActionsByID: async ({ commit }, { productVariantID }) => {

		const data = await contentService.fetchUserContentActionsByID({
			productVariantID: productVariantID,
		}).then(
			(content) => {
				var data = content?.payload;
				return data;
			},
			(error) => {
				var errorMessage;
				var errorIcon = "exclamation-triangle";
				Vue.toasted.error(errorMessage, {
					icon: errorIcon,
					theme: "outline",
					position: "bottom-center",
					singleton: true,
					duration: 3000,
				});
				console.log(error);
			}
		);

		var contentActions = {};
		data.forEach(item => {
			let list = contentActions[item.content.id] ?? [];
			list.push(item)

			contentActions[item.content.id] = list;
		});

		state.contentActions = contentActions;

		return data;
	},
	getUserContentAction: async ({ commit }, { id = null, contentId = null, dataType = null }) => {
		const data = await contentService.getUserContentAction(id, contentId, dataType).then(
			(content) => {
				var data = content?.payload;

				if(data && data.length>0){
					data.forEach((val,ind,arr)=>{
						var contentID=val.content?.id??val.contentID;
						if(!state.contentActions[contentID])
						{
							state.contentActions[contentID]=[];
						}
						var finded = state.contentActions[contentID].find(x=>x.dataType==val.dataType);
						if(finded)
						{
							finded.data=val.data;
							var temp = state.contentActions;
							state.contentActions=null;
							state.contentActions=temp;
						}else{
							state.contentActions[contentID].push(val);
							var temp = state.contentActions;
							state.contentActions=null;
							state.contentActions=temp;
						}
					});
				}





				return data;
			},
			(error) => {
				var errorMessage;
				var errorIcon = "exclamation-triangle";
				Vue.toasted.error(errorMessage, {
					icon: errorIcon,
					theme: "outline",
					position: "bottom-center",
					singleton: true,
					duration: 3000,
				});
				console.log(error);
				//alert(error);
			}
		);
		return data;
	},

	getContentTrainers: async ({ commit }, id) => {
		console.log("getContentById", id);
		commit("setLoading", true);
		const data = await contentService.fetchContentTrainers(id).then(
			(trainers) => {
				var data = trainers.payload;
				commit("setContentTrainers", { contentId: id, data });
				commit("setLoading", false);
			},
			(error) => {
				var errorMessage;
				if (error == "122")
					errorMessage = "You are not allowed to access this content!";
				Vue.toasted.error(errorMessage, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					singleton: true,
					duration: 3000,
				});
				console.log(error);
				//alert(error);
				commit("setLoading", false);
			}
		);
	},

	fetchAllContent: async ({ commit }) => {
		console.log("fetchAllContent");
		commit("setLoading", true);
		const data = await contentService.fetchAllContent().then(
			(content) => {
				var data = content?.payload;
				data = data.map((c) => {
					if (c.type.toLowerCase() === "directory") {
						return new Directory(c);
					} else if (c.type.toLowerCase() === "recordedvideos") {
						return new RecordedVideo(c);
					} else if (c.type.toLowerCase() === "liveclass") {
						return new LiveClass(c);
					} else return c;
				});
				return data;
			},
			(error) => {
				Vue.toasted.error(error, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				//alert(error);
				commit("setLoading", false);
				return [];
			}
		);
		console.log("all root content data", data);
		if (data) {
			commit("setRootContent", data);
		}

		commit("setLoading", false);
	},

	getUpcomingContent: async ({ commit }, { startDate, endDate, productVariantID = null }) => {
		console.log("getUpcomingContent", { startDate, endDate, productVariantID });
		const data = await contentService
			.fetchUpcomingContent(startDate, endDate, productVariantID)
			.then(
				(content) => {
					var data = content?.payload;
					// console.log(data);
					var upcoming = [];
					if (data) {
						upcoming = data.map((item) => {
							if (item.type.toLowerCase() === "directory") {
								return new Directory(item);
							} else if (item.type.toLowerCase() === "recordedvideos") {
								return new RecordedVideo(item);
							} else if (item.type.toLowerCase() === "liveclass") {
								return new LiveClass(item);
							} else return item;
						});
					}
					return upcoming;
				},
				(error) => {
					Vue.toasted.error(error, {
						icon: "exclamation-triangle",
						theme: "outline",
						position: "bottom-center",
						duration: 3000,
					});
					//alert(error);
					commit("setLoading", false);
					return [];
				}
			);
		console.log("upcoming content data", data);
		if (data) {
			commit("setUpcomingContent", data);
		}

		commit("setLoading", false);
	},

	async createDirectoryContent({ commit }, content) {
		commit("setLoading", true);
		var entity;
		if (content.parentDirectoryID) {
			entity = "section";
			var nextSequence =
				state.content[content.parentDirectoryID].children &&
					state.content[content.parentDirectoryID].children.length > 0
					? state.content[content.parentDirectoryID].children.at(-1).sequence +
					1
					: 1;
			console.log(nextSequence);
			content.sequence = nextSequence;
		} else entity = "subject/module";

		// state.loading = true;
		console.log("createDirectory", content);
		const data = await contentService.createDirectoryContent(content).then(
			(product) => {
				var data = product?.payload;
				console.log("response", data);
				// content.id = data;
				// data = new Directory(data);
				if (content.parentDirectoryID) {
					var contentObj = {
						...data,
						children: [],
						parentDirectoryID: content.parentDirectoryID,
					};

					commit("addContentToParentDirectory", contentObj);
				}
				commit("setLoading", false);
				return data;
			},
			(error) => {
				var errorMessage;
				var errorIcon = "exclamation-triangle";
				if (error.message == "115") {
					errorMessage = "You are not allowed to create a " + entity;
					// errorIcon = "lock";
				} else if (error.message == "123") {
					errorMessage =
						"Your access is blocked, please contact your administrator/trainer for more information.";
					errorIcon = "lock";
				}
				Vue.toasted.error(errorMessage, {
					icon: errorIcon,
					theme: "outline",
					position: "bottom-center",
					singleton: true,
					duration: 3000,
				});
				console.log(error);
				//alert(error);
				commit("setLoading", false);
				return "error-" + error.message;
			}
		);
		// .catch((error) => {
		// 	// console.error(error);
		// 	Vue.toasted.error(error, {
		// 		icon: "exclamation-triangle",
		// 		theme: "outline",
		// 		position: "bottom-center",
		// 		singleton: true,
		// 		duration: 3000,
		// 	});
		// 	commit("setLoading", false);
		// });

		return data;
	},

	async createLiveClassContent({ commit }, content) {
		if (content.parentDirectoryID) {
			var nextSequence =
				state.content[content.parentDirectoryID].children &&
					state.content[content.parentDirectoryID].children.length > 0
					? state.content[content.parentDirectoryID].children.at(-1).sequence +
					1
					: 1;
			console.log(nextSequence);
			content.sequence = nextSequence;
		}

		commit("setLoading", true);
		// state.loading = true;
		// console.log("createLiveClass", content);
		const data = await contentService.createLiveClassContent(content).then(
			(content) => {
				var data = content?.payload;
				console.log("response", data);
				// data = new Directory(data);
				return data;
			},
			(error) => {
				Vue.toasted.error(error, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				console.log(error);
				commit("setLoading", false);
				return null;
			}
		);

		if (Array.isArray(data)) {
			var contentObj = {
				data: data.map((content) => new LiveClass(content)),
				parentDirectoryID: content.parentDirectoryID,
			};
			commit("addContentArrayToParentDirectory", contentObj);
		} else {
			data.parentDirectoryID = content.parentDirectoryID;
			commit("addContentToParentDirectory", new LiveClass(data));
		}
		commit("setLoading", false);
		return data;
	},

	async createFlashCards({ commit }, content) {
		if (content.parentDirectoryID) {
			var nextSequence =
				state.content[content.parentDirectoryID].children &&
					state.content[content.parentDirectoryID].children.length > 0
					? state.content[content.parentDirectoryID].children.at(-1).sequence +
					1
					: 1;
			console.log(nextSequence);
			content.sequence = nextSequence;
		}

		commit("setLoading", true);
		const data = await contentService.createFlashCardsContent(content).then(
			(content) => {
				var data = content?.payload;
				console.log("response", data);
				return data;
			},
			(error) => {
				Vue.toasted.error(error, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				console.log(error);
				commit("setLoading", false);
				return null;
			}
		);
	},



	async setUserContentAction({ commit }, contentAction) {
		// state.loading = true;
		// console.log("createLiveClass", content);
		const data = await contentService.setUserContentAction(contentAction).then(
			(content) => {
				var data = content?.payload;
				// data = new Directory(data);
				return data;
			},
			(error) => {
				Vue.toasted.error(error, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				console.log(error);
				return null;
			}
		);
		return data;
	},

	async exportAttendanceReport({ commit }, { contentID, productVariantID }) {

		var result = undefined;
		await contentService.exportAttendanceReport(contentID, productVariantID).then(
			(response) => {
				result = response;
			},
			(error) => {
				console.log(error);
				// handleError(error);
			}
		);
		const contentDisposition = result.headers.get('content-disposition');
		const filename = contentDisposition.split('filename=')[1].split(';')[0];
		console.log(filename);

		const blob = await result.blob();

		const newBlob = new Blob([blob]);
		const blobUrl = window.URL.createObjectURL(newBlob);
		return { name: filename, url: blobUrl };
	},
	async exportContentAttendanceReport({ commit }, contentID) {

		var result = undefined;
		await contentService.exportContentAttendanceReport(contentID).then(
			(response) => {
				result = response;
			},
			(error) => {
				console.log(error);
				// handleError(error);
			}
		);
		const contentDisposition = result.headers.get('content-disposition');
		const filename = contentDisposition.split('filename=')[1].split(';')[0];
		console.log(filename);

		const blob = await result.blob();

		const newBlob = new Blob([blob]);
		const blobUrl = window.URL.createObjectURL(newBlob);
		return { name: filename, url: blobUrl };
	},
	async exportUserAttendanceReport({ commit }, userID) {

		var result = undefined;
		await contentService.exportUserAttendanceReport(userID).then(
			(response) => {
				result = response;
			},
			(error) => {
				console.log(error);
			}
		);
		const contentDisposition = result.headers.get('content-disposition');
		const filename = contentDisposition.split('filename=')[1].split(';')[0];
		console.log(filename);

		const blob = await result.blob();

		const newBlob = new Blob([blob]);
		const blobUrl = window.URL.createObjectURL(newBlob);
		return { name: filename, url: blobUrl };
	},
	async updateContent({ commit }, content) {
		commit("setLoading", true);
		// state.loading = true;
		// console.log("createLiveClass", content);
		var updatedContent = await contentService.updateContent(content).then(
			(response) => {
				var data = response?.payload;
				console.log("response", data);
				// data = new Directory(data);
				return data;
			},
			(error) => {
				
				if(typeof(error)=="object" && error.message)
				{
					error=error.message;
				}
				Vue.toasted.error(error, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				console.log(error);
				commit("setLoading", false);
			}
		);
		console.log(updatedContent);

		switch (updatedContent.type) {
			case ContentType.RECORDED_VIDEOS:
				updatedContent = new RecordedVideo(updatedContent);
				break;
			case ContentType.LIVE_CLASS:
				updatedContent = new LiveClass(updatedContent);
				break;
			case ContentType.STUDY_MATERIAL:
				var tempContent = updatedContent;
				// if updated content data is null take our null 
				// object which we passed while updating
				if(updatedContent && !updatedContent.data){
					tempContent.data = content.data;
				}
				updatedContent = new LiveClass(tempContent);
				break;
			case ContentType.DIRECTORY:
				updatedContent = new Directory(updatedContent);
				break;
		}

		if (content.parentDirectoryID) {
			commit("updateContentInParent", {
				content: updatedContent,
				parentDirectoryID: content.parentDirectoryID,
			});
		} else {
			commit("setContent", updatedContent);
			updatedContent.productVariantID = content.productVariantID;
			store.commit(
				"productVariant/updateContentInProductVariantDetails",
				updatedContent
			);
		}
		commit("setLoading", false);
		return updatedContent;
	},

	async createRecordedVideosContent({ commit }, content) {
		if (content.parentDirectoryID) {
			var nextSequence =
				state.content[content.parentDirectoryID].children &&
					state.content[content.parentDirectoryID].children.length > 0
					? state.content[content.parentDirectoryID].children.at(-1).sequence +
					1
					: 1;
			console.log(nextSequence);
			content.sequence = nextSequence;
		}
		commit("setLoading", true);
		// state.loading = true;
		console.log("createRecordedVideosContent", content);
		const data = await contentService.createRecordedVideosContent(content).then(
			(content) => {
				var data = content?.payload;
				console.log("response", data);
				// data = new Directory(data);
				return data;
			},
			(error) => {
				Vue.toasted.error(error, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				console.log(error);
				commit("setLoading", false);
			}
		);
		data.parentDirectoryID = content.parentDirectoryID;
		// console.log(data);

		commit("addContentToParentDirectory", new RecordedVideo(data));
		commit("setLoading", false);
		return data;
	},

	async createStudyMaterialContent({ commit }, content) {
		if (content.parentDirectoryID) {
			var nextSequence =
				state.content[content.parentDirectoryID].children &&
					state.content[content.parentDirectoryID].children.length > 0
					? state.content[content.parentDirectoryID].children.at(-1).sequence +
					1
					: 1;
			console.log(nextSequence);
			content.sequence = nextSequence;
		}
		commit("setLoading", true);
		// state.loading = true;
		console.log("createStudyMaterialContent", content);
		const data = await contentService.createStudyMaterialContent(content).then(
			(content) => {
				var data = content?.payload;
				console.log("response", data);
				// data = new Directory(data);
				return data;
			},
			(error) => {
				Vue.toasted.error(error, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				console.log(error);
				commit("setLoading", false);
			}
		);

		// console.log("id: ", id);
		// commit("addProductToCategory", { data, id: id });

		// commit("addContentArrayToParentDirectory", {
		// 	data: data,
		// 	parentDirectoryID: content.parentDirectoryID,
		// });
		data.parentDirectoryID = content.parentDirectoryID;

		commit("addContentToParentDirectory", data);
		commit("setLoading", false);
		// Vue.set(state.content, content.parentDirectoryID, data);
		return data;
	},
	async createAssignmentContent({ commit }, content) {
		if (content.parentDirectoryID) {
			var nextSequence =
				state.content[content.parentDirectoryID].children &&
				state.content[content.parentDirectoryID].children.length > 0
					? state.content[content.parentDirectoryID].children.at(-1).sequence +
					  1
					: 1;
			content.sequence = nextSequence;
		}
		commit("setLoading", true);
		// state.loading = true;
		const data = await contentService.createAssignmentContent(content).then(
			(content) => {
				var data = content?.payload;
				console.log("response", data);
				// data = new Directory(data);
				return data;
			},
			(error) => {
				Vue.toasted.error(error, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				console.log(error);
				commit("setLoading", false);
			}
		);

		
		data.parentDirectoryID = content.parentDirectoryID;

		commit("addContentToParentDirectory", data);
		commit("setLoading", false);
		return data;
	},

	async uploadContentFile({ commit }, content) {
		commit("setLoading", true);
		// state.loading = true;
		console.log("uploadContentFile", ...content);
		const data = await contentService.uploadContentFile(content).then(
			(res) => {
				var data = res?.payload;
				console.log("response", data);
				// data = new Directory(data);
				return data;
			},
			(error) => {
				Vue.toasted.error(error, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				console.log(error);
				commit("setLoading", false);
			}
		);
		// console.log("id: ", id);
		// commit("addProductToCategory", { data, id: id });
		commit("setLoading", false);
		return data;
	},

	async assignTrainersToContent({ commit }, content) {
		commit("setLoading", true);
		// state.loading = true;
		console.log("assignTrainersToContent", content);
		const data = await contentService.assignTrainersToContent(content).then(
			(content) => {
				var data = content?.payload;
				console.log("response", content);
				// data = new Directory(data);
				return content;
			},
			(error) => {
				Vue.toasted.error(error, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				console.log(error);
				commit("setLoading", false);
			}
		);
		// console.log("id: ", id);
		// commit("addTrainerToContent", data);
		commit("setLoading", false);
		return data;
	},

	async unassignTrainersToContent({ commit }, content) {
		commit("setLoading", true);
		// state.loading = true;
		console.log("unassignTrainersToContent", content);
		const data = await contentService.unassignTrainersToContent(content).then(
			(content) => {
				var data = content?.payload;
				console.log("response", content);
				// data = new Directory(data);
				return content;
			},
			(error) => {
				Vue.toasted.error(error, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				console.log(error);
				commit("setLoading", false);
			}
		);
		// console.log("id: ", id);
		// commit("addTrainerToContent", data);
		commit("setLoading", false);
		return data;
	},

	async deleteContent({ commit }, content) {
		commit("setLoading", true);
		// state.loading = true;
		console.log("deleteContent", content);
		await contentService
			.deleteContent(content.id)
			.then(() => {
				commit("deleteContent", content);
				commit("setLoading", false);
				// if (content.productVariantID)
				Vue.toasted.success(content.entity + " deleted successfully", {
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
			})
			.catch((error) => {
				// console.log(error);
				commit("setLoading", false);
				return error;
			});
	},

	async deleteProductVariantItem({ commit }, content) {
		commit("setLoading", true);
		// state.loading = true;
		console.log("deleteContent", content);
		await contentService
			.deleteProductVariantItem(content)
			.then(() => {
				content.items[0].productVariantID = content.productVariantID;
				commit("deleteContent", content.items[0]);
				commit("setLoading", false);
				// if (content.productVariantID)
				Vue.toasted.success("Subject deleted successfully", {
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
			})
			.catch((error) => {
				// console.log(error);
				commit("setLoading", false);
				return error;
			});
	},

	async editContentSequence({ commit }, { content, id }) {
		commit("setLoading", true);
		await contentService.editContentSequence({ content, id }).then(
			async (res) => {
				Vue.toasted.success("Sequence updated successfully!", {
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				if(content.parentContentID) {
					await contentService.fetchContentById(content.parentContentID, contentService.getCurrentPlatform()).then((x) => {
						if(x && x.payload && x.payload.data && x.payload.data.children) {
							state.content[content.parentContentID].children = [];
							state.content[content.parentContentID].children = x.payload.data.children;
						}
					});
				}
			},
			(error) => {
				Vue.toasted.error(error, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				console.log(error);
				commit("setLoading", false);
			}
		);
		commit("setLoading", false);
	},

	async editProductVariantItemsSequence({ commit }, { content, productVariantItemID }) {
		commit("setLoading", true);
		await contentService.editProductVariantItemsSequence({ content, productVariantItemID }).then(
			(res) => {
				Vue.toasted.success("Sequence updated successfully!", {
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
			},
			(error) => {
				Vue.toasted.error(error, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				console.log(error);
				commit("setLoading", false);
			}
		);
		commit("setLoading", false);
	},
	getLiveClassContentAttendance: async ({ commit }, {
		productVariantID,
		currentPage = 1,
		pageSize = 10000
	}) => {
		commit("setLoading", true);
		const data = await contentService.getLiveClassContentAttendance({
			productVariantID: productVariantID,
			currentPage: currentPage,
			pageSize: pageSize,
		}).then(
			(content) => {
				var data = content?.payload;
				return data;
			},
			(error) => {
				Vue.toasted.error(error, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				commit("setLoading", false);
				return [];
			}
		);
		if (data) {
			commit("setLoading", false);
			return data;
		}
	},
	fetchContentAttendance: async ({ commit },  {contentID,userID,productVariantID}) => {
		commit("setLoading", true);
		const data = await contentService.fetchContentAttendance(contentID,userID,productVariantID).then(
			(content) => {
				var data = content?.payload;
				return data;
			},
			(error) => {
				Vue.toasted.error(error, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				commit("setLoading", false);
				return [];
			}
		);
		if (data) {
			commit("setLoading", false);
			return data;
		}
	},
	fetchContentAttendanceDetail: async ({ commit }, { contentID, userID }) => {
		commit("setLoading", true);
		const data = await contentService.fetchContentAttendanceDetail(contentID, userID).then(
			(content) => {
				var data = content?.payload;
				return data;
			},
			(error) => {
				Vue.toasted.error(error, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				commit("setLoading", false);
				return [];
			}
		);
		if (data) {
			commit("setLoading", false);
			return data;
		}
	},

	getQuizResultByProductVariantId: async ({ commit }, id) => {
		commit("setLoading", true);
		const data = await contentService.getQuizResultByProductVariantId(id)
		.then((content) => {
				var data = content?.payload;
				return data;
			},
			(error) => {
				commit("setLoading", false);
				return [];
			}
		);
		if (data) {
			commit("setLoading", false);
			return data;
		}
	},

	fetchSingleLiveClassAttendance: async ({ commit }, {contentId, userId = null}) => {
		commit("setLoading", true);
		const data = await contentService.fetchLiveClassAttendance(contentId, {userId: userId})
		.then((content) => {
				var data = content?.payload;
				return data;
			},
			(error) => {
				Vue.toasted.error(error, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				commit("setLoading", false);
				return [];
			}
		);
		if (data) {
			commit("setLoading", false);
			return data;
		}
	},

	fetchLiveClassAttendance: async ({ commit }, id) => {
		console.log("fetchLiveClassAttendance");
		commit("setLoading", true);
		const data = await contentService.fetchLiveClassAttendance(id, {userId: null}).then(
			(content) => {
				var data = content?.payload;
				return data;
			},
			(error) => {
				Vue.toasted.error(error, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				commit("setLoading", false);
				return [];
			}
		);
		if (data) {
			commit("setLoading", false);
			return data;
		}
	},

	markLiveClassAttendance: async ({ commit }, attendanceData) => {
		console.log("markLiveClassAttendance", attendanceData);
		commit("setLoading", true);
		const data = await contentService
			.markLiveClassAttendance(attendanceData)
			.then(
				(content) => {
					var data = content?.payload;
					return data;
				},
				(error) => {
					Vue.toasted.error(error, {
						icon: "exclamation-triangle",
						theme: "outline",
						position: "bottom-center",
						duration: 3000,
					});
					commit("setLoading", false);
					return [];
				}
			);
		if (data) {
			commit("setLoading", false);
			return data;
		}
	},
	updateAction({ commit }, data) {
		let contentId = data.contentID;
		debugger;
		if (state.contentActions && state.contentActions[contentId]) {
			var obj = state.contentActions[contentId].find(x => x.dataType == data.dataType);
			if (obj) {
				obj.data = data.data;
			}
			else {
				state.contentActions[contentId].push(data);
			}
			var temp = state.contentActions;
			state.contentActions = {};
			state.contentActions = temp;
		} else {
			state.contentActions[contentId] = [data];
			var temp = state.contentActions;
			state.contentActions = {};
			state.contentActions = temp;
		}

	},
	markAllLiveClassAttendance: async ({ commit }, attendanceData) => {
		console.log("markAllLiveClassAttendance", attendanceData);
		commit("setLoading", true);
		const data = await contentService
			.markAllLiveClassAttendance(attendanceData)
			.then(
				(content) => {
					var data = content?.payload;
					return data;
				},
				(error) => {
					Vue.toasted.error(error, {
						icon: "exclamation-triangle",
						theme: "outline",
						position: "bottom-center",
						duration: 3000,
					});
					commit("setLoading", false);
					return [];
				}
			);
		if (data) {
			commit("setLoading", false);
			return data;
		}
	},

	async createAssignmentContent({ commit }, content) {
		if (content.parentDirectoryID) {
			var nextSequence =
				state.content[content.parentDirectoryID].children &&
				state.content[content.parentDirectoryID].children.length > 0
					? state.content[content.parentDirectoryID].children.at(-1).sequence +
					  1
					: 1;
			content.sequence = nextSequence;
		}
		commit("setLoading", true);
		const data = await contentService.createAssignmentContent(content).then(
			(content) => {
				var data = content?.payload;
				console.log("response", data);
				return data;
			},
			(error) => {
				Vue.toasted.error(error, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				console.log(error);
				commit("setLoading", false);
			}
		);

		
		data.parentDirectoryID = content.parentDirectoryID;

		commit("addContentToParentDirectory", data);
		commit("setLoading", false);
		return data;
	},

	async createYoutubeContent({ commit }, content) {
		if (content.parentDirectoryID) {
			var nextSequence =
				state.content[content.parentDirectoryID].children &&
				state.content[content.parentDirectoryID].children.length > 0
					? state.content[content.parentDirectoryID].children.at(-1).sequence +
					  1
					: 1;
			content.sequence = nextSequence;
		}
		commit("setLoading", true);
		const data = await contentService.createYoutubeContent(content).then(
			(content) => {
				var data = content?.payload;
				console.log("response", data);
				return data;
			},
			(error) => {
				Vue.toasted.error(error, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				console.log(error);
				commit("setLoading", false);
			}
		);

		
		data.parentDirectoryID = content.parentDirectoryID;

		commit("addContentToParentDirectory", data);
		commit("setLoading", false);
		return data;
	},
	
	async createFreeTextContent({ commit }, content) {
		if (content.parentDirectoryID) {
			var nextSequence =
				state.content[content.parentDirectoryID].children &&
				state.content[content.parentDirectoryID].children.length > 0
					? state.content[content.parentDirectoryID].children.at(-1).sequence +
					  1
					: 1;
			content.sequence = nextSequence;
		}
		commit("setLoading", true);
		const data = await contentService.createFreeTextContent(content).then(
			(content) => {
				var data = content?.payload;
				console.log("response", data);
				return data;
			},
			(error) => {

				if(typeof(error)=="object" && error.message)
				{
					error=error.message;
				}


				Vue.toasted.error(error, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				console.log(error);
				commit("setLoading", false);
			}
		);

		
		data.parentDirectoryID = content.parentDirectoryID;

		commit("addContentToParentDirectory", data);
		commit("setLoading", false);
		return data;
	},
};

const mutations = {

	// updateFilteredContent(state, data){
	// 	state.filters = data.filters ?? {};

	// 	if(!state.filters || Object.keys(state.filters).length === 0){
	// 		state.filteredContent = state.content;
	// 	}

	// 	else{

	// 		for (let item of list) {
	// 			let actionList = this.contentActions[item.id] ?? [];

	// 			for (let action of actionList) {
	// 				if (action.dataType && action.dataType === 'bookmark') {
	// 					if (action.data === 'true' && this.isFilterSelected('bookmark', 'bookmark_content')) {
	// 						filteredList.push(item);
	// 						break;
	// 					}
	// 				}
	// 				if (action.dataType && action.dataType == 'confidence_level') {
	// 					if (action.data.toLowerCase() === 'high' && this.isFilterSelected('high', 'confidence_level')) {
	// 						filteredList.push(item);
	// 						break;
	// 					}
	// 					if (action.data.toLowerCase() === 'medium' && this.isFilterSelected('medium', 'confidence_level')) {
	// 						filteredList.push(item);
	// 						break;
	// 					}
	// 					if (action.data.toLowerCase() === 'low' && this.isFilterSelected('low', 'confidence_level')) {
	// 						filteredList.push(item);
	// 						break;
	// 					}
	// 				}

	// 				if (action.dataType && action.dataType == 'completionStatus') {
	// 					if (action.data.toLowerCase() === 'completed' && this.isFilterSelected('completed', 'completionStatus')) {
	// 						filteredList.push(item);
	// 						break;
	// 					}
	// 					if (action.data.toLowerCase() === 'uncompleted' && this.isFilterSelected('uncompleted', 'completionStatus')) {
	// 						filteredList.push(item);
	// 						break;
	// 					}
	// 					if (action.data.toLowerCase() === 'in_progress' && this.isFilterSelected('in_progress', 'completionStatus')) {
	// 						filteredList.push(item);
	// 						break;
	// 					}
	// 					if (action.data.toLowerCase() === 'not_started' && this.isFilterSelected('not_started', 'completionStatus')) {
	// 						filteredList.push(item);
	// 						break;
	// 					}
	// 				}
	// 			}
	// 		}

	// 	}
	// },
	setContent(state, data) {
		console.log("setContent", data);
		if (state.content[data.id]) data.children = state.content[data.id].children;
		state.content = { ...state.content, [data.id]: data };
		// Vue.set(state.content, data.id, data);
		// state.content[data.id] = data;
		state.loading = false;
	},
	setRootContent(state, data) {
		state.allRootContent = data;
		state.loading = false;
	},
	setUpcomingContent(state, data) {
		// console.log(data);
		state.upcomingContent = data;
		state.loading = false;
	},
	addTrainerToContent(state, data) {
		state.content[data.id].trainers = data.trainers;
		state.loading = false;
	},
	addContentToParentDirectory(state, data) {
		console.log("addContentToParentDirectory", data);
		if (state.content[data.parentDirectoryID]) {
			var tempContentObj = state.content[data.parentDirectoryID];

			if (tempContentObj.children) {
				var found = tempContentObj.children.find(
					(child) => child.id === data.id
				);
				if (!found) {
					tempContentObj.children.push(data);
				} else {
					tempContentObj.children = [
						...tempContentObj.children.map((child) => {
							if (child.id === data.id) {
								console.log({ child, data });
								return data;
							}
							return child;
						}),
					];
				}
			} else {
				tempContentObj.children = [data];
			}

			console.log(state.content);
			console.log(
				"parent id",
				data.parentDirectoryID,
				typeof data.parentDirectoryID
			);
			state.content = {
				...state.content,
				[data.parentDirectoryID]: { ...tempContentObj },
			};
			state.loading = false;
		}
	},
	removeContentFromParentDirectory(state, data) {
		var parent = state.content[data.parentDirectoryID];
		var childrenWithChildRemoved = parent.children.filter(
			(child) => child.id != data.id
		);
		parent.children = childrenWithChildRemoved;
		state.content = { ...state.content, [data.parentDirectoryID]: parent };
	},
	addContentArrayToParentDirectory(state, contentData) {

		console.log("addContentArrayToParentDirectory", contentData.data);
		if (state.content[contentData.parentDirectoryID]) {
			var tempContentObj = state.content[contentData.parentDirectoryID];

			if (tempContentObj.children) {
				tempContentObj.children = [
					...tempContentObj.children,
					...contentData.data,
				];
			} else {
				tempContentObj.children = contentData.data;
			}
			state.content = {
				...state.content,
				[contentData.parentDirectoryID]: { ...tempContentObj },
			};
		}
	},
	updateContentChildren(state, contentData) {
		if (state.content[contentData.parentDirectoryID]) {
			var tempContentObj = state.content[contentData.parentDirectoryID];


			tempContentObj.children = contentData.data;

			state.content = {
				...state.content,
				[contentData.parentDirectoryID]: { ...tempContentObj },
			};
		}
	},
	updateContentInParent(state, { content, parentDirectoryID }) {
		// console.log("updateContentInParent", parentDirectoryID, content);
		if (state.content[parentDirectoryID]) {
			var tempObj = state.content[parentDirectoryID];
			tempObj.children = tempObj.children.map((child) => {
				console.log("checking parent children", child);
				if (child.id == content.id) {
					console.log("replacing content in parent", content);
					return content;
				}
				return child;
			});
			console.log(state.content);
			state.content = {
				...state.content,
				[parentDirectoryID]: { ...tempObj },
			};
		}
	},
	setContentTrainers(state, { contentId, data }) {
		state.trainersByContentId = {
			...state.trainersByContentId,
			[contentId]: data,
		};
	},
	setLoading(state, data) {
		if (data) {
			console.log("content loading...");
		} else {
			console.log("content loading complete!");
		}
		state.loading = data;
	},
	clearContent(state) {
		console.log("clearing content");
		state.content = {};
		state.upcomingContent = [];
		state.allRootContent = [];
	},
	deleteContent(state, data) {
		delete state.content[data.id];
		if (data.productVariantID) {
			store.commit(
				"productVariant/removeContentFromProductVariantDetails",
				data
			);
		} else if (data.parentDirectoryID) {
			store.commit("content/removeContentFromParentDirectory", data);
		}
	},
	setUploading(state, status) {
		state.uploadingContent = status;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
