<template>
	<div class="body">
		<div class="body-content p-4">
			<!-- <b-tabs nav-class="main-tabs nav nav-tabs" nav-wrapper-class="main-tabs-row" content-class="eb-content-wrapper">
				<b-tab title="My Courses">-->
					<div class="eb-body container">

						
						<DashboardUpdatesCard v-if="isMobile" class="mobile-reminders"></DashboardUpdatesCard>
						<div class="eb-content eb-col-wrapper">
							<div class="m-auto no-products" v-if="!user.productVariants || user.productVariants.length <= 0">
								<h4 class="title mb-3"> You have not enrolled in any courses yet. </h4>

								<img 
									src="/img/course-management-icon.480dd1b0.svg" 
									class="sidebar-item-icon color-change-svg" 
									alt="image not found"
								/>

								<button role="button" class="mt-3 create-section" @click="() => {
									$router.push('/search');
								}">
									Explore Courses <i class="fa fa-arrow-right"></i>
								</button>
							</div>
							<div class="eb-left-col pe-2" v-else>
								
								<div class="tab-content" id="myTabContent">

									<div class="tab-pane show active" id="allCoursesPane" role="tabpanel" aria-labelledby="allCoursesTab"
										tabindex="0"> 		
										<div class="eb-content eb-col-wrapper">									
											<UserDashboardProducts></UserDashboardProducts>	
										</div>
									</div><!-- /tab-pane -->

									<div class="tab-pane" id="myListPane" role="tabpanel" aria-labelledby="myListTab"
										tabindex="0"> 		
										<div class="eb-content eb-col-wrapper">
											<div class="px-4 py-5">My courses list......	</div>
										</div>
									</div><!-- /tab-pane -->

									<div class="tab-pane" id="wishlistPane" role="tabpanel" aria-labelledby="wishlistTab"
										tabindex="0"> 		
										<div class="eb-content eb-col-wrapper">
											<div class="px-4 py-5">Wishlist......</div>	
										</div>
									</div><!-- /tab-pane -->

								</div><!-- /tab-content -->

							</div>
							<div class="eb-right-col py-3 notification-col" id="coursesNotification" >
								 <DashboardUpdatesCard v-if="!isMobile" />
							</div><!-- /eb-right-col -->		
						</div>
						

						
	
						
			
 					</div>				
<!--				</b-tab>				
			</b-tabs>			 -->

		</div>
	</div><!-- /body -->
</template>

<script>
import { mapActions, mapState } from "vuex";
import UserDashboardProducts from "../components/UserDashboard/UserDashboardProducts.vue"
import DashboardUpdatesCard from "./../components/Dashboard/DashboardUpdatesCard.vue";
export default {
	name: "UserDashboard",
	data() {
		return {
			showCoursesNotification: false
		};
	},
	components: {
		UserDashboardProducts, DashboardUpdatesCard
	},
	methods: {
		...mapActions("user", ["fetchCurrentUser"]),
		...mapActions("pricing", ["fetchInvoiceByUserId"]),
	},
	computed: {
		...mapState("user", ["user"]),
		...mapState("appState", ["isMobile"]),
	},
	mounted() {
		
		this.$nextTick(() => {
			window.postMessage({ event: "dashboard-loaded", data: null });
		});
	},
	created() {
		
		this.fetchCurrentUser().then(() => {
			console.log(this.user,"user");
			// console.log("userdata");
			this.fetchInvoiceByUserId(this.user.id).then((res) => {
				console.log("invoices: ", res);
				var unenrolledCourses = this.user.productVariants.filter(
					(variant) =>
						variant.subscriptionStatus &&
						variant.subscriptionStatus.toLowerCase() == "unenrolled"
				);
				var userInvoices = this.invoicesByUserId[this.user.id];

				unenrolledCourses.forEach((course) => {
					var found = userInvoices.find((invoice) =>
						invoice.details.find(
							(detail) => detail.productSourceID == course.id
						)
					);
					// console.log(found);
					if (found) {
						console.log("Invoice found for unenrolled course", found);
					}
				});

				// console.log({
				// 	unenrolledCourses,
				// 	invoicesByUserId: this.invoicesByUserId[this.user.id],
				// });
			});

			checkCourses();
			this.$nextTick(() => {
				// const event = new CustomEvent("courses-fetched");
				// document.dispatchEvent(event);
				window.postMessage({ event: "courses-fetched", data: null });
			});
		});
	},
};
function checkCourses() {
	if (typeof getUserProductsCallback !== "undefined") {
		getUserProductsCallback();
	}
}
</script>

<style scoped>
.no-products {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.mobile-reminders{
	display: none;
}
.no-products > img {
    height: 100px;
    width: 100px;
}

.create-section {
  background: #ffffff;
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0.875rem 1.125rem;
  font-size: 1.125rem;
  line-height: 1.37;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.75rem;
  color: #000000;
}

.create-section img {
  width: 1.25rem;
  height: 1.25rem;
}
</style>

<style>
.eb-course-list-wrapper {
	width: 100%
}
.eb-col-wrapper {
	display: flex;
}

.eb-left-col {
	width: 72%;
}

.eb-right-col {
	width: 36%;
}

.eb-course-list {
	list-style: none;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	column-gap: 2.5%;
}

.eb-list-item {
	width: 25.5%;
	margin-bottom: 30px;
}

.eb-list-item .eb-item-img img {
	max-width: 100%;
	border-radius: 6px;
	height: 160px;
    width: 100%;
    object-fit: cover;
}

.eb-list-item .eb-item-title {
	font-weight: 700;
	font-size: 16px;
	line-height: 1.3;
	margin: 15px 0 10px;
	height: 42px;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.eb-list-item .eb-item-instructor {
	display: flex;
	align-items: center;
	color: var(--gray);
	margin-bottom: 10px;
	font-size: 13px;
}

.eb-list-item .eb-item-instructor img {
	width: 28px;
	height: 28px;
	border-radius: 50%;
}
.eb-list-item .eb-item-instructor .user-avatar {
	width: 28px;
    height: 28px;
    min-width: 28px;
}
.eb-list-item .eb-item-instructor > span {
    line-height: 1.1;
}

.eb-list-item .eb-item-rating-bar {
	display: flex;
	justify-content: space-between;
	padding-bottom: 10px;
}

.eb-rating-stars {
	font-size: 20px;
	display: flex;
	line-height: 18px;
}

.eb-rating-stars .star {
	color: #dddddd;
}

.eb-rating-stars .star.checked {
	color: #FFCC00;
}

.eb-rating-counts {
	font-size: 12px;
}

.eb-list-item .progress {
	height: 5px;
}

.eb-list-item .progress-bar {
	background-color: var(--progress-bar);
}

.eb-item-progress-label {
	font-size: 13px;
	font-weight: 600;
}

.eb-sidebar-classes-wrapper {
	border: solid 1px var(--border-color);
	border-radius: 6px;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);
}

.eb-sidebar-classes-wrapper h3 {
	color: var(--primary-color);
	text-transform: uppercase;
	font-size: 15px;
	font-weight: 700;
}

.eb-sidebar-classes .eb-lc-box:last-child {
	margin-bottom: 0;
}

.eb-lc-box {
	border: solid 1px var(--border-color);
	border-radius: 6px;
	padding: 14px 10px;
	margin-bottom: 15px;
	box-shadow: 0 1px 6px rgba(0,0,0,0.07);
}

.eb-lc-time {
	font-size: 12px;
	color: var(--gray);
	line-height: 1.3;
}

.eb-lc-label {
	font-size: 12px;
	font-weight: 600;
	color: var(--primary-color);
	border: solid 1px var(--border-color);
	border-radius: 4px;
	padding: 3px 5px 2px;
	display: flex;
	align-items: center;
	align-self: center;
}

.eb-lc-label img {
	max-width: 16px;
	margin-right: 3px;
}

.eb-lc-label.label-live {
	border-color: var(--primary-color);
}

.eb-lc-label.label-assignment {
	border-color: var(--blue-color);
	color: var(--blue-color);
}

.eb-lc-box .btn {
	background-color: rgb(29, 193, 58);;
	border-color: rgb(29, 193, 58);;
	font-size: 13px;
	font-weight: 700;
	width: 90px;
	padding: 5px 6px;
}

.eb-lc-box .btn:hover,
.eb-lc-box .btn:focus {
	background-color: rgba(29, 193, 58, 0.9);
	border-color: rgba(29, 193, 58, 0.9);
}

.eb-lc-head {
	padding-bottom: 10px;
}

.eb-lc-box h5 {
	font-weight: 700;
	font-size: 1.085rem;
	margin-bottom: 4px;
}

.eb-lc-box h6 {
	color: var(--gray-2);
	font-size: 13px;
	line-height: 1.3;
	margin: 0;
	font-weight: 400;
}

@media only screen and (max-width: 1200px) {
	.eb-list-item {
		width: 29.5%;
		margin-bottom: 30px;
	}
}

@media only screen and (max-width: 980px)  and (min-width: 768px) {

	.eb-list-item
	{
		width: 30%;
	}
	.eb-left-col {
		width: 100%;
	}
	
	.eb-right-col {
		display: none;
	}
}
@media only screen and (max-width: 992px) {
	.eb-body{
		padding: 0 !important;
	}
	.eb-left-col {
		width: 100%;
	}
	.eb-right-col {
		display: none;
	}
	.eb-list-item
	{
		width: 100%;
		border: solid 1px var(--border-color);
		padding: 10px;
		display: flex;
		border-radius: 8px;
		margin-bottom: 15px;
	}
	.eb-item-img{
		width:50%;
		padding-right:10px;
		height: auto;
    	object-fit: cover;
	}
	.eb-item-desc{
		width:65%;
	}
	.eb-list-item .eb-item-title{
		/* margin-top:0; */
	}
	.notification-col{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		background-color: #fff;
		z-index: 10;
		height: 100%;
		z-index: 9999991 !important;
		transform: translateX(101%);
		display: block;
		transition: transform 0.15s ease-in;
	}
	.notification-col.active{
		transform: translateX(0%);
	}
	.eb-col-wrapper .notification-col{
		padding: 0 !important;
	}
	.notification-col > div{
		/* height: 100%;
		overflow: auto; */
	}
	.mobile-reminders{
		display: block !important;
	}
	#coursesNotification{
		display: none;
	}
}

@media only screen and (max-width: 556px) {
	.eb-item-img {
		padding-right: 0px;
	}

	.eb-list-item {
		flex-direction: column;
		align-items: center;
		width: 100%;
	}

	.eb-course-list {
		justify-content: center;
	}

	.eb-item-img {
		width: 100%;
	}
}
</style>
