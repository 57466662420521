<template>
	<div>
		<div class="video-container" v-if="isVSS">
			<div
				v-if="contentData.data.status.toLowerCase() == 'processing'"
				class="video-processing"
			>
				<i class="fas fa-cogs"></i>
				Video is currently processing. The video will be available soon...
			</div>
			<div
				v-if="contentData.data.status.toLowerCase() == 'notuploaded'"
				class="video-processing"
			>
				<i class="fas fa-exclamation-triangle"></i>
				The video is either corrupted or incorrectly uploaded. Please inform
				your trainer or the administrator.
			</div>
			<iframe
        allow="encrypted-media; fullscreen"
				v-if="contentData.data.viewUrl || contentData.data.url"
				:src="contentData.data.viewUrl"
				class="video-player"
			></iframe>
		</div>
		<div class="video-container" v-if="isYoutube">			
			<iframe
				allow="fullscreen"
				v-if="contentData.data.url"
				:src="contentData.data.url"
				class="video-player"
			></iframe>
		</div>
		<div class="video-details-container">
			<ul class="inline-list video-details-tabs">
				<li
					@click="changeTab"
					:class="{ tab: true, active: activeTab == 'overview' }"
					id="overview"
				>
					Overview
				</li>
			</ul>
			<div class="video-details-content">
				<h2>{{ contentData.title }}</h2>
				<h5 v-if="productVariantData && productVariantData.product">
					{{ productVariantData.product.title }} ({{
						productVariantData.title
					}})
				</h5>
				<!-- <h5>Chapter: {{ chapterName }}</h5> -->
				<!-- <h5 class="mt-4">Trainer: {{ productVariantData.creator.name }}</h5> -->
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "RecordedVideo",
	data() {
		return {
			activeTab: "overview",
		};
	},
	methods: {
		changeTab(e) {
			this.activeTab = e.target.id;
		},
	},
	props: {
		productVariantData: Object,
		contentData: Object,
	},
	computed: {
		isVSS(){
			return this.contentData && this.contentData.externalService && this.contentData.externalService.serviceProvider.toLowerCase()=='vss';
		},
		isYoutube(){
			return this.contentData && this.contentData.externalService && this.contentData.externalService.serviceProvider.toLowerCase()=='youtube';
		}
		// videoTitle() {
		// 	var title = "";
		// 	this.contentData.map((content) => {
		// 		if (content.id == this.$route.params.id) {
		// 			title = content.content[0].title;
		// 		}
		// 	});
		// 	return title;
		// },
		// chapterName() {
		// 	var chapter = "";
		// 	this.contentData.map((content) => {
		// 		if (content.id == this.$route.params.id) {
		// 			chapter = content.title;
		// 		}
		// 	});
		// 	return chapter;
		// },
	},
	created() {
		// document.location.href = this.contentData.data.viewUrl;
	},
};
</script>

<style scoped>
.floating-menu {
    top: 0px;
}
.video-container {
	display: flex;
	height: 57.75vh;
}

.video-player {
	width: 100%;
}

.video-details-tabs {
	box-shadow: 1px 1px 5px var(--color-gray);
}

.video-details-tabs .tab {
	padding: 2rem !important;
	font-size: 1.15rem;
}

.active {
	border-bottom: 5px solid var(--primary-color);
}

.video-details-content {
	padding: 1rem;
}

.video-processing {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	background-color: #fafafa;
	font-size: 1.5rem;
	font-weight: 500;
	color: #999;
	row-gap: 1rem;
	padding: 0 1rem;
	text-align: center;
}

.video-processing .fas {
	font-size: 3rem;
}

</style>

