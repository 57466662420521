<template>
    <b-modal id="certificate-modal-action" @change="$emit('updateModalState', false)"
        :dialog-class="'animate-modal-dialog ' + (isExpanded ? 'expanded' : 'shrink')" :visible="visible" hide-header
        centered content-class="certificate-modal-action" size="xl" body-class="modal-body" hide-footer
        modal-class="custom-modal" no-close-on-backdrop>
        <div class="body-container">
            <div class="right-close-panel">
                <button @click="() => { isExpanded = !isExpanded; }" class="close-button expanded-btn mr-3" role="button">
                    <i v-if="!isExpanded" class="fas fa-expand-alt"></i>
                    <i v-if="isExpanded" class="fas fa-compress-alt"></i>
                </button>
                <button @click="$emit('updateModalState', false)" class="close-button" role="button">
                    <img src="@/assets/images/cancel-icon.svg" />
                </button>
            </div>

            <h1 class="modal-title">
                {{ isEditMode ? 'Edit Certificate' : 'Create Certificate' }}
            </h1>

            <div class="body-content-container">
                <div class="info-container expand">

                    <div class="form-group my-3">
                        <label for="title" class="main-label">Certificate Title (Required)</label>
                        <input placeholder="Type your certificate title" v-model="certificateName" class="form-input"
                            type="search" name="title" id="title" required>
                    </div>

                    <div class="certificate-section">
                        <div class="section-header">
                            <span class="minus-icon">−</span>
                            <div>
                                <p class="subtitle">Choose Your Certification Qualification Criteria</p>
                            </div>
                        </div>

                        <div class="criteria-options">
                            <!-- Option: On Passing 1 Exam -->
                            <label class="criteria-option">
                                <label class="checkbox-wrapper">
                                    <input type="checkbox" v-model="selectedCriteria" value="exam" name="criteria"
                                        >
                                    <span class="checkbox-custom"></span>
                                </label>

                                <div class="criteria-content">
                                    <span class="criteria-label">Passing Exam</span>
                                    <ItemSelector @click.stop :items="exams" class="criteria-content-value"
                                        :initialItems="selectedExams" :maxHeight="'200px'"
                                        @items-changed="handleItemsChanged">
                                        <template #trigger-button="{ toggleMenu, selectedItems }">
                                            <button @click="toggleMenu" class="trigger-button">
                                                <span>{{ selectedItems.length > 0 ? `${selectedItems.length} items selected`
                                                    : 'Select items' }}</span>
                                            </button>
                                        </template>

                                        <template #item-builder="{ item, selected }">
                                            <span class="item">
                                                <span class="item-title">{{ item.title }}</span>
                                                <span class="circle" :class="{ 'selected': selected }">
                                                    <span v-if="selected" class="tick">&#10003;</span>
                                                </span>
                                            </span>
                                        </template>
                                    </ItemSelector>
                                </div>
                            </label>

                            <!-- Option: On Content Completion -->
                            <label class="criteria-option">
                                <div class="checkbox-wrapper">
                                    <input type="checkbox" v-model="selectedCriteria" value="content" name="criteria"
                                        >
                                    <span class="checkbox-custom"></span>
                                </div>
                                <div class="criteria-content">
                                    <span class="criteria-label">Content Completion</span>
                                    <ItemSelector @click.stop :items="contents" class="criteria-content-value"
                                        :initialItems="selectedContents" :maxHeight="'200px'"
                                        @items-changed="handleContentSelection">
                                        <template #trigger-button="{ toggleMenu, selectedItems }">
                                            <button @click="toggleMenu" class="trigger-button">
                                                <span>{{ selectedItems.length > 0 ? `${selectedItems.length} items selected`
                                                    : 'Select items' }}</span>
                                            </button>
                                        </template>

                                        <template #item-builder="{ item, selected }">
                                            <span class="item">
                                                <span class="item-title">{{ item.title }}</span>
                                                <span class="circle" :class="{ 'selected': selected }">
                                                    <span v-if="selected" class="tick">&#10003;</span>
                                                </span>
                                            </span>
                                        </template>
                                    </ItemSelector>
                                </div>
                            </label>

                            <!-- Option: On Course Completion Percentage -->
                            <label class="criteria-option">
                                <div class="checkbox-wrapper">
                                    <input type="checkbox" v-model="selectedCriteria" value="percentage" name="criteria">
                                    <span class="checkbox-custom"></span>
                                </div>
                                <div class="criteria-content">
                                    <span class="criteria-label">Course Completion Percentage</span>
                                    <div v-if="selectedCriteria.includes('percentage')"
                                        class="percentage-input criteria-content-value">
                                        <input placeholder="Completion %" class="form-input" type="number"
                                            id="completion-percentage" v-model="completionPercentage" min="0" max="100"
                                            required @input="validateCompletionPercentage" />
                                    </div>
                                </div>
                            </label>
                        </div>


                        <div class="form-group">
                            <div style="display: flex; align-items:center;">
                                <div class="form-check form-switch me-3" style=" margin-top: 19px; width: 200px;">
                                    <input class="form-check-input" type="checkbox" role="switch" id="hasValidity"
                                        v-model="hasValidity" />
                                    <label class="form-check-label" for="hasValidity">Has Validity Period</label>
                                </div>

                                <div v-if="hasValidity">
                                    <input placeholder="Enter validity period" class="form-input col-md-3 me-2"
                                        type="number" style=" width: 200px;" id="validityValue"
                                        v-model.number="validityValue" required min="1" />
                                    <select class="form-select col-md-3"
                                        style="height: 43px; width: 200px; border:none; border-bottom: 1.5px solid #b2b2b2;"
                                        id="validityUnit" v-model="validityUnit" required>
                                        <option value="days">Days</option>
                                        <option value="months">Months</option>
                                        <option value="years">Years</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="step-btn-container">
                    <button :disabled="!isFormValid" @click="handleSubmit" role="button" class="step-btn">
                        {{ isEditMode ? 'Update' : 'Create' }}
                        <img src="@/assets/images/arrow-right.svg" />
                    </button>
                </div>
            </div>
        </div>
    </b-modal>
</template>



<script>
import { mapState, mapActions } from "vuex";
import { ContentType } from "../../../constants/content";
import { EntityType } from "../../../constants/entity";
import { CertificateType, CertificateCriteriaType } from "../../../constants/certificate";
import { CertificateModel, CertificateCriteriaModel } from "../../../models/certificate";
import ItemSelector from "../../Shared/ItemSelector.vue";

export default {
    name: "ManageCertificateModal",
    props: {
        visible: Boolean,
        isEditMode: Boolean,
        certificateData: Object,
    },
    data() {
        return {
            isExpanded: false,
            certificateName: null,
            selectedCriteria: [],
            exams: [],
            contents: [],
            completionPercentage: null,
            selectedExams: [],
            selectedContents: [],
            hasValidity: false,
            validityValue: 1,
            validityUnit: "days",
            existingCriterias: [],
        };
    },
    components: {
        ItemSelector,
    },
    computed: {
        isFormValid() {
            if (!this.certificateName?.trim()) {
                return false;
            }

            if (!this.selectedCriteria?.length) {
                return false;
            }

            return this.selectedCriteria.every(criteria => {
                if (criteria === "exam") {
                    return this.selectedExams?.length > 0;
                } else if (criteria === "content") {
                    return this.selectedContents?.length > 0;
                } else if (criteria === "percentage") {
                    return this.completionPercentage > 0 && this.completionPercentage <= 100;
                }

                return false;
            });
        },
    },
    methods: {
        ...mapActions("certificate", ["addCertificate", "updateCertificate", "fetchCertificateByID"]),
        ...mapActions("content", ["getAllChildContentByProductVariant"]),

        async loadContents() {
            const contents = await this.getAllChildContentByProductVariant({ productVariantID: this.$route.params.id });
            this.contents = contents.filter(content => content.type !== ContentType.DIRECTORY && content.type !== ContentType.EXAM);
            this.exams = contents.filter(content => content.type === ContentType.EXAM);
        },

        closeModal() {
            this.$emit("updateModalState", false);
            this.resetModel();
        },

        resetModel() {
            this.isExpanded = false;
            this.certificateName = null;
            this.selectedCriteria = [];
            this.exams = [];
            this.contents = [];
            this.completionPercentage = null;
            this.selectedExams = [];
            this.selectedContents = [];
            this.hasValidity = false;
            this.validityValue = 1;
            this.validityUnit = "days";
            this.existingCriterias = [];
        },

        async populateFormData() {
            if (!this.isEditMode || !this.certificateData) return;

            const certificate = await this.fetchCertificateByID(this.certificateData.id) || this.certificateData;
            this.certificateName = certificate.certificateName;
            this.existingCriterias = certificate.criterias;
            this.selectedCriteria = this.determineSelectedCriteria(certificate.criterias);
            this.selectedExams = this.getSelectedExams(certificate.criterias);
            this.selectedContents = this.getSelectedContents(certificate.criterias);
            this.completionPercentage = this.getCompletionPercentage(certificate.criterias);
            this.setValidityPeriod(certificate.defaultValidityPeriodSeconds);
        },

        determineSelectedCriteria(criterias) {
            if (!criterias) return [];

            const result = [];
            const criteriaTypeMapping = {
                [CertificateCriteriaType.EXAM_PASSING]: "exam",
                [CertificateCriteriaType.COMPLETION]: "content",
                [CertificateCriteriaType.PERCENTAGE_COMPLETION]: "percentage"
            };

            criterias.forEach(criteria => {
                const mappedValue = criteriaTypeMapping[criteria.criteriaType];
                if (mappedValue && !result.includes(mappedValue)) {
                    result.push(mappedValue);
                }
            });

            return result.length > 0 ? result : [];
        },


        getSelectedExams(criterias) {
            return criterias
                .filter(c => c.criteriaType === CertificateCriteriaType.EXAM_PASSING)
                .map(c => this.exams.find(exam => exam.id === c.entityID) || { id: c.entityID, title: "Unknown", type: "Exam" });
        },

        getSelectedContents(criterias) {
            return criterias
                .filter(c => c.criteriaType === CertificateCriteriaType.COMPLETION)
                .map(c => this.contents.find(content => content.id === c.entityID) || { id: c.entityID, title: "Unknown", type: "Content" });
        },

        getCompletionPercentage(criterias) {
            const percentageCriteria = criterias.find(c => c.criteriaType === CertificateCriteriaType.PERCENTAGE_COMPLETION);
            return percentageCriteria ? JSON.parse(percentageCriteria.criteriaValue || '{"percentage": 100}').percentage : 100;
        },

        setValidityPeriod(seconds) {
            this.hasValidity = !!seconds;
            if (this.hasValidity) {
                this.validityUnit = 'days';
                this.validityValue = Math.floor(seconds / 86400);
            } else {
                this.validityValue = 0;
            }
        },

        handleDone() {
            this.$emit("done");
            this.closeModal();
        },

        handleItemsChanged(selectedItems) {
            this.selectedExams = selectedItems;
        },

        handleContentSelection(selectedItems) {
            this.selectedContents = selectedItems;
        },

        validateCompletionPercentage() {
            this.completionPercentage = Math.min(Math.max(this.completionPercentage, 0), 100);
        },

        getValidityPeriodInSeconds() {
            const conversionFactors = {
                days: 86400,
                months: 2592000,
                years: 31536000
            };

            if (this.validityUnit === 'years') {
                const months = this.validityValue * 12;
                return months * conversionFactors['months'];
            } else if (this.validityUnit === 'months') {
                const days = this.validityValue * 30;
                return days * conversionFactors['days'];
            } else if (this.validityUnit === 'days') {
                return this.validityValue * conversionFactors['days'];
            }
        },

        handleSubmit() {
            const validityInSeconds = this.getValidityPeriodInSeconds();
            const { selectedCriteria, selectedExams, selectedContents, completionPercentage, existingCriterias } = this;

            if (!this.isCriteriaValid(selectedCriteria, selectedExams, selectedContents, completionPercentage)) return;

            const criterias = this.createCertificateCriterias(selectedCriteria, selectedExams, selectedContents, completionPercentage, existingCriterias);
            const certificate = this.createCertificate(criterias, validityInSeconds);

            const action = this.isEditMode ? this.updateCertificate : this.addCertificate;

            action(certificate)
                .then(() => this.handleSuccess())
                .catch((error) => this.handleError(error));
        },

        isCriteriaValid(selectedCriterias, selectedExams, selectedContents, completionPercentage) {
            if (!selectedCriterias.length) return this.showError("No criteria selected.");

            for (let selectedCriteria of selectedCriterias) {
                switch (selectedCriteria) {
                    case "exam":
                        if (!selectedExams.length) return this.showError("No exams selected for certificate.");
                        break;
                    case "content":
                        if (!selectedContents.length) return this.showError("No content selected for certificate.");
                        break;
                    case "percentage":
                        if (completionPercentage == null || completionPercentage <= 0 || completionPercentage > 100)
                            return this.showError("Completion percentage must be between 1 and 100.");
                        break;
                    default:
                        return this.showError("Invalid criteria selected.");
                }
            }

            return true;
        },

        createCertificateCriterias(selectedCriterias, selectedExams, selectedContents, completionPercentage, existingCriterias = []) {
            const criteriaModels = [];

            selectedCriterias.forEach(selectedCriteria => {
                const contents = selectedCriteria === "exam" ? selectedExams : selectedContents;
                const selectedCriteriaType = this.getSelectedCriteriaType(selectedCriteria);
                const criteriaEntityType = this.getCriteriaEntityType(selectedCriteria);
                const criteriaData = selectedCriteria === "percentage"
                    ? JSON.stringify({ percentage: completionPercentage })
                    : JSON.stringify({ completed: true });

                if (selectedCriteria === "percentage") {
                    const existingCriteria = existingCriterias.find(c => c.entityID === this.$route.params.id && c.criteriaType === selectedCriteriaType);

                    criteriaModels.push(new CertificateCriteriaModel({
                        id: existingCriteria ? existingCriteria.id : null,
                        entityID: this.$route.params.id,
                        entityType: criteriaEntityType,
                        criteriaType: selectedCriteriaType,
                        criteriaValue: criteriaData,
                    }));
                } else {
                    contents.forEach(x => {
                        const existingCriteria = existingCriterias.find(c => c.entityID === x.id && c.criteriaType === selectedCriteriaType);

                        criteriaModels.push(new CertificateCriteriaModel({
                            id: existingCriteria ? existingCriteria.id : null,
                            entityID: x.id,
                            entityType: criteriaEntityType,
                            criteriaType: selectedCriteriaType,
                            criteriaValue: criteriaData,
                        }));
                    });
                }
            });

            return criteriaModels;
        },


        getSelectedCriteriaType(selectedCriteria) {
            switch (selectedCriteria) {
                case "exam": return CertificateCriteriaType.EXAM_PASSING;
                case "content": return CertificateCriteriaType.COMPLETION;
                case "percentage": return CertificateCriteriaType.PERCENTAGE_COMPLETION;
                default: return "";
            }
        },

        getCriteriaEntityType(selectedCriteria) {
            switch (selectedCriteria) {
                case "exam":
                case "content": return EntityType.CONTENT;
                case "percentage": return EntityType.PRODUCTVARIANT;
                default: return null;
            }
        },

        createCertificate(criterias, validityInSeconds) {
            return new CertificateModel({
                id: this.certificateData?.id,
                entityID: this.$route.params.id,
                entityType: EntityType.PRODUCTVARIANT,
                defaultValidityPeriodSeconds: this.hasValidity ? validityInSeconds : null,
                certificateName: this.certificateName,
                certificateType: this.getCertificateType(),
                criterias,
            });
        },

        getCertificateType() {
            if (!this.selectedCriteria || this.selectedCriteria.length === 0) {
                return null;
            }

            const hasExam = this.selectedCriteria.includes("exam");
            const hasCompletion = this.selectedCriteria.includes("content") || this.selectedCriteria.includes("percentage");

            return hasCompletion ? CertificateType.COMPLETION : CertificateType.EXAM;
        },

        handleSuccess() {
            const successMessage = this.isEditMode ? "Certificate updated successfully." : "Certificate created successfully.";
            this.handleDone();
            this.showSuccess(successMessage);
        },

        handleError(error) {
            const errorMessage = this.isEditMode ? "Failed to update certificate: " : "Failed to create certificate: ";
            this.showError(errorMessage + error.message);
        },

        showError(message, options = {}) {
            this.$toasted.error(message, { icon: "exclamation-triangle", theme: "outline", position: "bottom-right", singleton: true, duration: 5000, ...options });
        },

        showSuccess(message, options = {}) {
            this.$toasted.success(message, { icon: "check", theme: "outline", position: "bottom-right", duration: 3000, ...options });
        },

        resetValidity() {
            if (!this.hasValidity) {
                this.validityValue = null;
                this.validityUnit = "days";
            }
        },
    },
    watch: {
        visible: async function () {
            if (this.visible) {
                await this.loadContents();
                await this.populateFormData();
            }
        },
        hasValidity() {
            this.resetValidity();
        },
    },
};
</script>



<style scoped>
.right-close-panel {
    position: absolute;
    top: 2.5rem;
    right: 2rem;
    display: flex;
}

.right-close-panel .close-button {
    position: initial;
}

.expanded-btn {
    color: black;
    font-size: 24px;
    margin-right: 15px;
    padding-top: 4px;
}

.body-content-container {
    flex-direction: column;
}

.body-content-container .info-container.expand {
    width: 100% !important;
    padding-right: 0px;
}



.info-container .main-label {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.37;
    text-transform: uppercase;
    color: #727272;
    margin-bottom: 1rem;
}

.form-textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.5;
    resize: vertical;
    transition: border-color 0.2s ease-in-out;
}

.form-textarea:focus {
    border-color: var(--color-red);
    outline: none;
    box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
}

.info-container .form-group {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.info-container .form-input {
    border: 0;
    border-bottom: 1.5px solid #b2b2b2;
    font-size: 1rem;
    outline: none;
    padding: 0.875rem 0;
    /* max-width: 21.625rem; */
    width: 100%;
}


.form-input:focus {
    border-color: var(--primary-color) !important;
}

.modal-body {
    padding: 0px;
}

.modal-title {
    color: #000;
    font-family: "Circular Std";
    font-size: 2rem;
    font-style: normal;
    font-weight: 450;
    line-height: 1.5rem;
    text-transform: capitalize;
}


.certificate-section {
    margin-bottom: 24px;
}

.section-header {
    display: flex;
    gap: 12px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.minus-icon {
    width: 24px;
    height: 24px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.subtitle {
    color: #6b7280;
    font-size: 14px;
    margin-top: 4px;
}

.criteria-options {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.criteria-option {
    display: flex;
    align-items: center;
    gap: 12px;
    position: relative;
    border: 1px solid var(--primary-color);
    border-radius: 1rem;
    padding: 10px;
}

.criteria-option .criteria-label {
    margin: .5rem 0;
    padding: 0;
    color: #000;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.2rem;
    text-transform: capitalize;
}

.criteria-option .form-input {
    padding: .5rem;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border-radius: 5px;
    border-radius: 10px;
    border: 1px solid #DCDCDC;
    background: #F4F4F4;
}

.criteria-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: -webkit-fill-available;
}

.criteria-content>* {
    flex-grow: 1;
}

.criteria-content-value {
    flex-grow: 6;
}

.checkbox-wrapper {
    display: flex;
    align-items: center;
    position: relative;
}

.checkbox-custom {
    width: 20px;
    height: 20px;
    border: 2px solid #e5e7eb;
    border-radius: 50%;
    /* Circular shape */
    display: inline-block;
    position: relative;
    background-color: white;
    /* White background when unchecked */
    cursor: pointer;
    /* Pointer cursor on hover */
    transition: all 0.3s ease;
    /* Smooth transition */
}

.criteria-option input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}

.criteria-option input[type="checkbox"]:checked+.checkbox-custom {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
    /* Background color when checked */
}

.criteria-option input[type="checkbox"]:checked+.checkbox-custom::after {
    content: '✓';
    color: white;
    /* Tick color */
    font-size: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* Center the tick */
    transition: all 0.2s ease;
}

.criteria-option .checkbox-custom:hover {
    border-color: var(--primary-color);
    /* Border color on hover */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    /* Light shadow on hover */
}
.select-container {
    flex: 1;
    margin-left: 16px;
    position: relative;
}

.custom-select {
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.custom-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    margin-top: 4px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    z-index: 10;
}

.search-box {
    padding: 8px;
    border-bottom: 1px solid #e5e7eb;
}

.search-box input {
    width: 100%;
    padding: 8px;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
}

.options-list {
    max-height: 200px;
    overflow-y: auto;
}

.option {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    cursor: pointer;
}

.form-select,
.form-input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
}

.add-certificate-btn {
    width: 100%;
    padding: 12px;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    margin-bottom: 24px;
}

.plus-icon {
    font-size: 18px;
    color: #6b7280;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
}

.btn-cancel,
.btn-done {
    padding: 8px 16px;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
}

.btn-cancel {
    background: white;
    border: 1px solid #e5e7eb;
}

.btn-done {
    background: #ef4444;
    border: none;
    color: white;
}

/* Transitions */
.modal-enter-active,
.modal-leave-active {
    transition: opacity 0.3s ease;
}

.modal-enter-from,
.modal-leave-to {
    opacity: 0;
}

/* Hide default radio and checkbox */
input[type="radio"] {
    position: absolute;
    opacity: 0;
}

.dropdown-arrow {
    transition: transform 0.2s ease;
}

.dropdown-arrow.open {
    transform: rotate(180deg);
}

.trigger-button {
    padding: .5rem;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border-radius: 5px;
    border-radius: 10px;
    border: 1px solid #DCDCDC;
    background: #F4F4F4;
}


.trigger-button span {
    color: #B1B1B1;
    font-family: "Circular Std";
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 450;
    line-height: 1.2rem;
    text-transform: capitalize;
}

.item {
    display: flex;
    justify-content: space-between;
}

.circle {
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    margin-right: 8px;
    transition: all 0.3s ease;
}

.circle.selected {
    background-color: var(--primary-color);
    border: 2px solid var(--primary-color);
}

.tick {
    color: white;
    font-size: 13px;
}

.item-title span {
    font-size: 14px;
    color: #333;
}
</style>
<style>
.certificate-modal-action {
    height: 45.375rem;
    /* width: 70vw; */
}

.certificate-modal-action .modal-body.modal-body {
    display: flex;
    flex-direction: row;
    padding: 0 !important;
    overflow: auto;
}

.certificate-modal-action .modal-body.modal-body .body-container {

    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

}

#certificate-modal-action .expanded {
    max-width: 96%;
    max-height: 96%;
}

#certificate-modal-action .expanded .certificate-modal-action {
    height: 90vh;
}</style>