<template>
	<aside class="sidebar">
		<div id="sidebarLectureDD" class="sidebar-lecture-dd"></div>


		<div class="sidebar-user">

			<v-select class="select2 search-input" v-model="selectedProductVariantId" :reduce="(option) => option.id"
				:options="userProducts" placeholder="Search by Course" :appendToBody="true" label="name">
				<template #selected-option="option">
					<div>
						<div id="sb-selected-author" class="sb-selected-author">{{ productTrainers }}</div>
						<div id="sb-selected-batch" class="sb-selected-course">{{ option.product.title }}</div>
						<div id="sb-selected-batch" class="sb-selected-batch">{{ option.title }}</div>
						<b-tooltip target="sb-selected-author" triggers="hover">
							{{ productTrainers }}
						</b-tooltip>
						<b-tooltip target="sb-selected-batch" triggers="hover">
							<b>{{ option.product.title }}</b><br/>
						    {{ option.title }}
						</b-tooltip>
					</div>
				</template>
				<template v-slot:option="option">
					{{ option.product.title }} - {{ option.title }}
				</template>
			</v-select>


			<div class="sidebar-progress">
				<div class="progress mb-1">
					<div class="progress-bar" role="progressbar" :style="`width: ${progress}%`" aria-valuenow="25"
						aria-valuemin="0" aria-valuemax="100"></div>
				</div>
				<div class="sb-progress-labels d-flex justify-content-between mb-1">
					<div class="progress-status progress-val"><span id="progressVal">{{ progress }}</span>%</div>
					<div class="progress-label">Progress</div>
				</div>
			</div><!-- /sidebar-progress -->

		</div>

		<!-- Sidebar Menu -->
		<div class="sidebar-nav">
			<nav class="mt-2">
				<ul class="nav nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
					<!-- Add icons to the links using the .nav-icon class
					   with font-awesome or any other icon font library -->
					<li :class="`nav-item ${($route.params.page && $route.params.page.toLowerCase() == 'chapters') ? 'active' : ''}`"
						@click="navigate('chapters')">
						<a class="nav-link">
							<i class="nav-icon fas fa-list"></i>
							<p>Chapters</p>
						</a>
					</li>
					<li :class="`nav-item ${($route.params.page && $route.params.page.toLowerCase() == 'flashcards') ? 'active' : ''}`"
						@click="navigate('flashcards')">
						<a role="button" class="nav-link">
							<i class="nav-icon fas fa-random"></i>
							<p>Flashcards </p>
						</a>
					</li>
					<!-- <li class="nav-item">
					<a href="quiz-inner.html" class="nav-link">
					  <i class="nav-icon fas fa-copy"></i>
					  <p>Quizzes </p>
					</a>
				  </li>-->
				  <li class="nav-item" @click="navigate('exams')"
				  :class="`nav-item ${($route.params.page && $route.params.page.toLowerCase() == 'exams') ? 'active' : ''}`">
					<a role="button" class="nav-link">
					  <i class="nav-icon fas fa-graduation-cap"></i>
					  <p>Exams </p>
					</a>
				  </li>     

				  <li role="button" @click="navigate('assignments')"
						:class="`nav-item ${($route.params.page && $route.params.page.toLowerCase() == 'assignments') ? 'active' : ''}`">
						<a class="nav-link">
							<i class="nav-icon fas fa-clipboard-list"></i>
							<p>Assignments </p>
						</a>
					</li>

					<li role="button" @click="navigate('liveclasses')"
						:class="`nav-item ${($route.params.page && $route.params.page.toLowerCase() == 'liveclasses') ? 'active' : ''}`">
						<a class="nav-link">
							<i class="nav-icon fas fa-video"></i>
							<p>Live Classes </p>
						</a>
					</li>
				</ul>
			</nav>
		</div>

	</aside>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { ProductVariantStatus,ProductVariantSubscriptionStatus } from "../../constants/productVariant";

export default {
	name: "ProductContentSidebar",
	data() {
		return {
			selectedProductVariantId: null
		};
	},
	computed: {
		...mapState("user", ["user", "trainers"]),
		...mapState("productVariant", ["productVariants", "productVariantsProgress"]),
		userProducts() {
			var myProducts =
				(this.user && this.user.productVariants
					? this.user.productVariants.filter(
						(productVariant) =>
							productVariant.status != ProductVariantStatus.DRAFT && 
							productVariant.product && 
							productVariant.product.title && 
							productVariant.subscriptionStatus == ProductVariantSubscriptionStatus.ALLOWED &&
							(!productVariant.endDate || new Date(productVariant.endDate) >= new Date()) &&
							(!productVariant.expiresAt || new Date(productVariant.expiresAt) >= new Date())
					)
					: []).map(x => {
						x.name = x.product.title + "  " + x.title;
						return x;
					});
			if (this.$route.params.id && myProducts.find(x => x.id == this.$route.params.id)) {
				this.selectedProductVariantId = this.$route.params.id;
			} else {
				this.selectedProductVariantId = myProducts && myProducts.length > 0 ? myProducts[0].id : null;
			}
			return myProducts;
		},
		progress() {
			if (this.productVariantsProgress && this.productVariantsProgress[this.selectedProductVariantId]) {
				var progressOB = this.productVariantsProgress[this.selectedProductVariantId];
				return Math.round((progressOB.completedTotalContent / progressOB.totalContent) * 100);
			}
			return 0;
		},
		productTrainers() {
			if (this.selectedProductVariantId && this.productVariants && this.productVariants.items && this.productVariants.items[this.selectedProductVariantId] && this.productVariants.items[this.selectedProductVariantId].properties && this.productVariants.items[this.selectedProductVariantId].properties.trainers) {
				var trainerList = JSON.parse(this.productVariants.items[this.selectedProductVariantId].properties.trainers);
				if (trainerList && trainerList.length > 0) {
					var self = this;
					var trainerList = trainerList.map(function (val, ind, arr) {
						return self.trainers?self.trainers.find(x => x.id == val):null;
					}).filter(x => x);
					if (trainerList.length > 0) {
						var names = trainerList[0].firstName + " " + trainerList[0].lastName;
						if (trainerList.length > 1) {
							names += " + " + (trainerList.length - 1) + " trainers" + (trainerList.length > 2 ? "s" : "");
						}
						return names;
					}
				}
			}
			return "";

		}
	},
	watch: {
		selectedProductVariantId: function () {
			if (this.$route.params.id != this.selectedProductVariantId) {
				this.$router.push({ params: { id: this.selectedProductVariantId } });

			}
			if (!this.productVariantsProgress || !this.productVariantsProgress[this.selectedProductVariantId]) {
				this.getProductVariantProgress(this.selectedProductVariantId);
			}
		}
	},
	created() {
		if (this.$route.params.id) {
			this.selectedProductVariantId = this.$route.params.id;
		}

	},
	mounted() {
	},
	methods: {
		...mapActions("productVariant", ["getProductVariantProgress"]),
		navigate(page) {
			var params = { ...this.$route.params };
			var queries = { ...this.$route.query };
			params.page = page;

			if (queries.contentId) {
				delete queries.contentId;
			}
			// here we gets an exception of navigation duplicated 
			// since we want to use that duplication so, we 
			// are catching and doing nothing here.
			
			this.$router
				.push({ params, query: queries })
				.catch((error) => {});
		}

	},
};
</script>

<style scoped>
.sidebar {
	left: 0;
	position: fixed;
	top: 74px;
	bottom: 0;
	z-index: 1030;
	width: 275px;
	background-color: #fff;
	border-right: solid 1px var(--border-color);
	z-index: 3;
}

.sidebar-nav .nav-group {
	position: relative;
	transition: background .15s ease-in-out;
}

.sidebar-nav {
	position: relative;
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 0;
	margin-bottom: 0;
	overflow-x: hidden;
	overflow-y: auto;
	list-style: none;
	padding: 0 20px;
}

.sidebar-nav .nav-item {
	margin-bottom: 4px;
}

.sidebar-nav .nav-link {
	display: flex;
	flex: 1;
	align-items: center;
	padding: 10px 16px;
	color: var(--primary-link-color);
	text-decoration: none;
	white-space: nowrap;
	border: var(--cui-sidebar-nav-link-border);
	border-radius: var(--cui-sidebar-nav-link-border-radius);
	transition: background .15s ease, color .15s ease;
	border: solid 2px transparent;
	border-radius: 8px;
	font-family: Circular Std;
	font-size: 13px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.sidebar-nav .active {
	background-color: rgba(255, 252, 252, 1);
}

.sidebar-nav .active .nav-link,
.sidebar-nav .nav-link:hover {
	color: var(--primary-color);
}

.sidebar-nav .active .nav-link {
	border-color: var(--primary-color);
}

.nav-sidebar .nav-link p {
	display: inline;
	margin: 0;
	white-space: normal;
}

.sidebar-nav .nav-item .nav-icon {
	margin-left: 0.05rem;
	font-size: 1rem;
	margin-right: 0.2rem;
	text-align: center;
	width: 1.6rem;
	color: var(--icon-color);
}

.sidebar-nav .active .nav-icon {
	color: var(--primary-color);
}

.sidebar-progress {
	padding: 8px 20px 0;
	font-size: 13px;
}

.progress-val {
	font-weight: 600;
}

.sidebar-progress .progress {
	height: 7px;
	margin: 0px;
}

.sidebar-progress .progress-bar {
	background:linear-gradient(109.6deg, rgb(255, 170, 0),rgb(156, 184, 40), rgb(81, 195, 70),rgb(7, 206, 100)) !important;
}


.vs__clear {
	display: none;
}

.vs--searchable .vs__dropdown-toggle {
	padding: 0 10px;
}

.sidebar-user>>>.vs__selected-options {
	padding: 0 4px 0 18px;
	width: calc(100% - 18px);
}

.sidebar-user>>>.vs__selected {
	margin: 0;
	padding: 0;
	line-height: 17px;
	flex-grow: 2;
	cursor: pointer;
	width:100%;
}
.sidebar-user>>>.vs__selected > div{
	width:100%;
}
.sidebar-user>>>.vs__selected-options{
	flex-wrap: nowrap;
}

.sidebar-user>>>input[type="search"] {
	flex-grow: 0.05;
	cursor: pointer;
	padding: 0;
	border-radius: 3px;
	opacity: 0;
	background-color: #fff;
}

.sidebar-user>>>.vs--open input[type="search"] {
	flex-grow: 2;
	width: 100%;
	border: solid 1px var(--border-color);
	margin-bottom: 25px;
	margin-top: 5px;
	padding: 2px 4px;
	opacity: 1;
}

.sidebar-user>>>.vs--searchable .vs__dropdown-toggle {
	padding-right: 10px;
}

.sidebar-user>>>.vs__actions {
	cursor: pointer;
	background: #eee;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	padding: 0;
	text-align: center;
	justify-content: center;
	margin-top: 4px;
}

.sidebar-user>>>.vs__open-indicator {
	fill: #444;
	transform: scale(0.65);
	stroke: #eee;
}

.sidebar-user>>>.vs--single.vs--open .vs__selected {
	opacity: 0;
}

.sidebar-user>>>.vs--open .vs__open-indicator {
	transform: scale(0.65) rotate(180deg);
}

.select-head {
	color: var(--primary-color);
	font-weight: bold;
}

.sidebar-user {
	padding: 20px 0px 10px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
	margin-bottom: 15px;
}

.sb-selected {
	font-size: 13px;
	line-height: 17px;
	text-align: left;
	color: var(--primary-link-color);
	font-weight: 500;
	padding: 0 30px 0 20px;
	display: block;
}

.sb-selected-course {
	color: var(--primary-color);
	font-family: Circular Std;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.sb-selected-batch {
	color: #767676;
font-family: Circular Std;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.sb-selected-author {
	color: #231F20;
font-family: Circular Std;
font-size: 14px;
font-style: normal;
font-weight: 350;
line-height: normal;
}

.sb-selected-course,
.sb-selected-author,
.sb-selected-batch {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.sb-selected:after {
	content: "";
	border: none;
	position: absolute;
	right: 12px;
	top: 0;
	background-color: #eee;
	width: 18px;
	height: 18px;
	text-align: center;
	border-radius: 50%;
	background-image: url("../../assets/images/arrow-bottom.svg");
	background-size: 9px 16px;
	background-repeat: no-repeat;
	background-position: center center;
}

.sb-selected.show:after {
	transform: rotate(180deg);
}

.sb-course-list,
#vs1__listbox {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.sb-course-list .dropdown-item {
	padding: 7px 0;
	font-size: 13px;
	line-height: 16px;
	white-space: normal;
}

.sb-course-list .dropdown-item:hover,
.sb-course-list .dropdown-item:focus,
.sb-course-list .dropdown-item:active {
	color: var(--primary-color);
	background-color: transparent;
}

.sidebar-user .dropdown-menu {
	width: 100%;
	border-radius: 0;
	border-width: 0 0 1px 0;
	max-height: calc(100vh - 200px);
	overflow: auto;
	box-shadow: 0 8px 4px -3px rgba(0, 0, 0, 0.08);
}

.sb-search-control {
	box-shadow: none !important;
}

.sb-search-control:focus {
	border-color: var(--gray);
}

@media (max-width: 768px){
	.sidebar {
		position: static;
		border-right: none;
		width: 100%;
		background-color: #f5f5f5;
	}
	.sidebar-nav nav{
		width: 100%;
    	overflow-x: scroll;
	}
	.sidebar-nav ul.nav-sidebar{
		flex-direction: row !important;
    	flex-wrap: nowrap;
	}
	.nav-sidebar .nav-link p {
		white-space: nowrap;
	}
	.sidebar-user {
		padding: 15px 0px 5px;
		box-shadow: none;
		margin-bottom: 0;
		background: #f5f5f5;
	}
	.sidebar-user>>>.vs--searchable .vs__dropdown-toggle {
		background: #f5f5f5;
	}

}

</style>

<style>
body .vs__dropdown-menu {
	margin-top: -10px !important;
}

body .vs__dropdown-menu .vs__dropdown-option {
	line-height: 1.22;
	margin: 0 0 3px;
	white-space: normal;
}

body .vs__dropdown-menu .vs__dropdown-option:hover,
body .vs__dropdown-menu .vs__dropdown-option--highlight {
	color: var(--primary-color);
	background: none !important;
}

@media (max-width: 768px){
	.vs__dropdown-menu{
		border-top-style: solid;
		padding: 10px 0 5px;
	}
	body .vs__dropdown-menu .vs__dropdown-option {
		font-size: 1rem;
	}
}
</style>
