<template>
	<div class="reminder-item">
		<div class="reminder-content-top">
			<span class="reminder-badge"
				><img
					class="reminder-badge-icon"
					src="../../assets/images/live-class.svg"
				/>
				{{ badgeText }}
			</span>
			<span class="reminder-time">
				<i class="far fa-clock reminder-time-icon"></i> {{ time }} <br />
				{{ date }}
			</span>
		</div>
		<div class="reminder-content-bottom">
			<div>
				<h4 class="reminder-title">
					{{ title }}
				</h4>
				<p class="reminder-subtitle">
					{{ subTitle }}
				</p>
			</div>
			<a
				@click="handleContentClick"
				role="button"
				:class="['reminder-cta', { disabled: isDisabled }]"
				>{{ ctaText }}</a
			>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { getCookie } from "../../helpers/CookieHelpers";
import {
	getTimeIn12Hours,
	toShortDateString,
} from "../../helpers/FormatHelpers";

export default {
	name: "ReminderListItem",
	data() {
		return {
			id: this.reminderItem.id,
			productVariantId: this.reminderItem.productVariantID,
			type: this.reminderItem.type.toLowerCase(),
			title: this.reminderItem.title,
			subTitle: this.reminderItem.productVariantTitle,
			//time: " 1:00 PM to 5:00 PM",
		};
	},
	props: { reminderItem: Object },
	computed: {
		...mapState("user", ["user"]),
		hasContentStarted() {
			return (
				new Date(
					new Date(this.reminderItem.availableFrom).getTime() - 5 * 60 * 1000
				) < new Date()
			);
		},
		// check if meeting has ended and 15 minutes have passed
		hasContentEnded() {
			return (
				new Date() >
				new Date(
					new Date(this.reminderItem.availableTo).setTime(
						new Date(this.reminderItem.availableTo).getTime() + 900000
					)
				)
			);
		},
		badgeText() {
			switch (this.type) {
				case "liveclass":
					return "Live Class";
				case "recordedvideos":
					return "Recorded Video";
				case "assignment":
					return "Assignment";
				case "test":
					return "Test";
			}
		},
		ctaText() {
			switch (this.type) {
				case "liveclass":
					return this.isDisabled && this.hasContentEnded
						? "Class ended"
						: "Join Now";
				case "recordedvideos":
					return "Watch Now";
				case "assignment":
					return "Submit";
				case "test":
					return "Submit";
			}
		},
		contentLink() {
			switch (this.type) {
				case "recordedvideos":
					return {
						path:
							"/content/video/" +
							this.title
								.toLowerCase()
								.replace(/[^a-zA-Z0-9 ]/g, "")
								.split(" ")
								.join("-") +
							"-" +
							this.id +
							"_" +
							this.productVariantId,
						// query: { productVariantId: this.productVariantId },
					};
				case "liveclass":
					return {
						path:
							"/content/liveclass/" +
							this.title
								.toLowerCase()
								.replace(/[^a-zA-Z0-9 ]/g, "")
								.split(" ")
								.join("-") +
							"-" +
							this.id +
							"_" +
							this.productVariantId,
						// query: { productVariantId: this.productVariantId },
					};
				case "assignment":
					return {
						path:
							"/content/assignment/" +
							this.title
								.toLowerCase()
								.replace(/[^a-zA-Z0-9 ]/g, "")
								.split(" ")
								.join("-") +
							"-" +
							this.id +
							"_" +
							this.productVariantId,
						// query: { productVariantId: this.productVariantId },
					};
			}
		},
		time() {
			return (
				getTimeIn12Hours(this.reminderItem.availableFrom) +
				" - " +
				getTimeIn12Hours(this.reminderItem.availableTo)
			);
		},
		date() {
			return toShortDateString(this.reminderItem.availableFrom);
		},
		isDisabled() {
			// console.log(new Date(this.reminderItem.availableFrom).getDate());
			if (!this.hasContentStarted || this.hasContentEnded) return true;
			else return false;
		},
	},
	methods: {
		// ...mapActions("content", ["getContentById"]),
		handleContentClick() {
			switch (this.type) {
				case "liveclass":
					this.$router.push(this.contentLink);
					return;
				default:
					this.$router.push(this.contentLink);
					return;
			}
		},
	},
};
</script>

<style scoped>
@media screen and (max-width: 768px) {
	.reminder-item {
		margin-bottom: 0 !important;
		margin-right: 0.25rem !important;
	}
}

.reminder-item {
	border: 1px solid #e5e5e5;
	border-radius: 8px;
	background-color: #fafafa;
	box-shadow: 0 6px 8px #e0e0e029;
	padding: 1.125rem;
	margin-bottom: 1.875rem;
	display: flex;
	flex-direction: column;
}

.reminder-title {
	font-size: 1.25rem;
	color: #000;
	font-weight: 500;
	margin-bottom: 0.25rem;
	/* width: 100%; */
}

.reminder-subtitle {
	font-size: 0.875rem;
	color: #959595;
	line-height: 1.5rem;
	font-weight: normal;
	margin-bottom: 0;
}

.reminder-badge {
	color: #fd0d1b;
	padding: 0.25rem 0.5rem;
	background-color: #ffffff;
	border: 1px solid #fd0d1b;
	font-size: 0.75rem;
	font-weight: 500;
	border-radius: 3px;
	display: flex;
	align-items: center;
	width: fit-content;
	height: 1.375rem;
	margin-bottom: 1rem;
}

.reminder-badge-icon {
	width: 12px;
	margin-right: 0.25rem;
}

.reminder-content-bottom {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.reminder-content-top {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.reminder-cta {
	padding: 0.25rem 1rem;
	background-color: #1dc13a;
	border-radius: 6px;
	font-weight: bold;
	font-size: 0.875rem;
	text-decoration: none;
	color: #ffffff;
	flex-shrink: 0;
}

.reminder-cta.disabled {
	opacity: 0.5;
	pointer-events: none;
}

.reminder-time {
	font-size: 0.75rem;
	color: #767676;
	text-align: right;
}

.reminder-time-icon {
	margin-right: 0.25rem;
}
</style>
