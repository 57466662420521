import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
var routesObject = require("./routes/default").default;
import API from "../helpers/ApiHelpers";

import externalComponent from "../utils/external-component";

Vue.use(VueRouter);

fetch(`${window.location.protocol}//${window.location.host}/config.json`).then(
	(res) =>
		res.json().then(async (res) => {
			var apiURL = res[window.location.host]
				? `${window.location.protocol}//${res[window.location.host]}`
				: res["*"] ? `${window.location.protocol}//${res["*"]}`
                : "https://lms-v4-api.techsol360.com";
		  // var apiURL = "https://st1.learn360lms.com";
      // var apiURL = "https://lms-api.gocbeglobal.com";

      
			store.state.appState.apiRef = res;
			store.state.appState.apiURL = apiURL;
			await API.regenerateUserToken().catch((error) => {
				Vue.toasted.error("You have logged in on an another device", {
					icon: "exclamation-triangle",
					duration: 3000,
					theme: "outline",
					position: "bottom-center",
				});
			});

      if (!store.state.appState.systemParameters) {
        store.dispatch("appState/fetchSystemParameters").then(async () => {
          if (store.state.appState.systemParameters.customCssPath)
            $("head").append(
              `<link rel="stylesheet" type="text/css" href="${store.state.appState.systemParameters.customCssPath}">`
            );
          if (store.state.appState.systemParameters.customJsPath) {
            $("head").append(
              `<script type="module" src="${store.state.appState.systemParameters.customJsPath}"></script>`
            );
          }
          store.state.appState.customFilesLoaded = true;
          var customRoutesPath = store.state.appState.systemParameters
            ? store.state.appState.systemParameters.CustomRoutesPath
            : null;
          if (customRoutesPath) {
            routesObject = require(`./routes/${customRoutesPath}`).default;
          }
          var defaultRoutes = routesObject.routes;
          var customRoutes = []; // to keep track of the custom routes
          var customPages = store.state.appState.systemParameters
            ? store.state.appState.systemParameters.CustomPages
            : null;
          if (customPages) {
            for (var page in customPages) {
              if (page.includes("/")) {
                var routeName = page.split("/")[0];
                var childName = page.split("/")[1];
                var routeToReplace = defaultRoutes.find(async (item) => {
                  if (item.name == routeName) {
                    var childToReplace = item.children.find(
                      (child) => child.name == childName
                    );
                    childToReplace.component = await externalComponent(
                      ...customPages[page]
                    );
                    console.log(childToReplace);
                  }
                  return childToReplace;
                });
                customRoutes.push(routeToReplace);
                router.addRoute(routeToReplace);
              } else {
                var routeToReplace = defaultRoutes.find(
                  (item) => item.name == page
                );
                routeToReplace.component = await externalComponent(
                  ...customPages[page]
                );
                router.addRoute(routeToReplace);
                console.log(routeToReplace);
                customRoutes.push(routeToReplace);
              }
            }
            var remainingRoutes = defaultRoutes.filter(
              (route) => !Object.keys(customPages).includes(route.name)
            );
            customRoutes = [...customRoutes, ...remainingRoutes];
            remainingRoutes.forEach((route) => router.addRoute(route));
          } else {
            defaultRoutes.forEach((route) => router.addRoute(route));
          }
        });
      }
    })
);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

// console.log(router.app);

// Meta Handling
router.beforeEach((to, from, next) => {
  if (routesObject.beforeEach) routesObject.beforeEach(to, from, next);
});

export default router;
