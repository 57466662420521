<template>
	<div id="course-certificate-container">
		<b-tabs v-model="currentActiveTab" nav-class="main-tabs nav nav-tabs" nav-wrapper-class="main-tabs-row"
			content-class="course-certificate-container-wrapper">

			<b-tab :active="currentActiveTab === 0">
				<template v-slot:title>
					<div class="tab-title-wrapper">
						<span class="tab-title">Certificates</span>
					</div>
				</template>

				<CertificateManagement v-if="currentActiveTab === 0" />
			</b-tab>

			<b-tab :active="currentActiveTab === 1">
				<template v-slot:title>
					<div class="tab-title-wrapper">
						<span class="tab-title">List of Students</span>
					</div>
				</template>
				<StudentCertificates v-if="currentActiveTab === 1"/>
			</b-tab>


		</b-tabs>

	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { currencyCodes } from "../../../constants/currencyCodes";
import { countryCodes } from "../../../constants/countryCodes";
import formatter from "../../../helpers/FormatHelpers";

import CertificateManagement from "./CertificateManagement.vue";
import StudentCertificates from "./StudentCertificates.vue";

export default {
	name: "CourseCertificate",
	data() {
		return {
			loading: false,
			currentActiveTab: 0,
		};
	},
	methods: {},
	components: { CertificateManagement, StudentCertificates },
	props: { productVariantData: Object },
	computed: {

	},
	created() {

	},
	watch: {

	},
};
</script>

<style scoped>
#course-certificate-container {
	background-color: #f5f5f5;
	height: -webkit-fill-available;
	padding: 0;
}

#course-certificate-container .course-certificate-container-wrapper {
	max-height: calc(100svh - var(--header-height) - 65px);
	overflow-y: auto;
	padding: 0px;
}

#course-certificate-container .main-tabs-row {
	height: auto;
	padding: 0px;
}

#course-certificate-container .main-tabs a,
#course-certificate-container .main-tabs .nav-link {
	height: 65px;
	padding: 0px 20px;
	line-height: normal;
}

#course-certificate-container .nav {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
	padding: 0px 40px;
}

#course-certificate-container .nav-item {
	display: inline-block;
	float: none !important;
	height: 65x;
	margin: 0;
}
</style>

<style scoped>
.tab-title-wrapper {
	display: flex;
	flex-direction: column;
}

.tab-title-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	box-sizing: border-box;
}

.tab-title {
	color: #000;
	font-family: "Circular Std";
	font-size: 12px;
	font-style: normal;
	font-weight: 450;
	line-height: normal;
	letter-spacing: -0.36px;
}

.loading-overlay {
	margin-top: 38dvh;
	margin-bottom: 38dvh;
}

.empty-list {
	display: flex;
	justify-content: space-around;
	font-size: 15px;
	margin-top: 25dvh;
	margin-bottom: 35dvh;
	font-weight: 700;
}
</style>
