<template>
    <div class="item-select" ref="itemSelect">
        <slot name="trigger-button" :toggleMenu="toggleMenu" :selectedItems="selectedItems">
            <button @click="toggleMenu" class="trigger-button">
                <span>{{ selectedItems.length > 0 ? `${selectedItems.length} items selected` : 'Select items' }}</span>
            </button>
        </slot>

        <transition name="fade">
            <div v-if="showMenu" class="item-menu" ref="itemMenu">
                <input type="text" v-model="searchQuery" class="search-bar" placeholder="Search By Title" />

                <div class="item-list" :style="{ maxHeight: computedMaxHeight }">
                    <ul class="item-list-items">
                        <li v-if="filteredItems.length === 0" class="no-items">
                            No items found
                        </li>
                        <li v-for="(item, index) in filteredItems" :key="item.id" @click="toggleSelection(item)"
                            class="item-item" :class="{ selected: isSelected(item) }">
                            <slot name="item-builder" :item="item" :selected="isSelected(item)">
                                <div class="item-title">
                                    <span>{{ item.title }}</span>
                                </div>
                            </slot>
                        </li>
                    </ul>
                </div>
            </div>
        </transition>
    </div>
</template>
  
  
<script>
export default {
    props: {
        items: {
            type: Array,
            required: true,
        },
        initialItems: {
            type: Array,
            default: () => [],
        },
        maxHeight: {
            type: String,
            default: 'calc(70vh - var(--header-height))',
        },
    },
    data() {
        return {
            selectedItems: [...this.initialItems],
            searchQuery: '',
            showMenu: false,
        };
    },
    computed: {
        filteredItems() {
            if (!this.searchQuery) {
                return this.items;
            }
            return this.items.filter(item =>
                item.title.toLowerCase().includes(this.searchQuery.toLowerCase())
            );
        },
        computedMaxHeight() {
            return this.maxHeight;
        },
    },
    watch: {
        initialItems(newItems) {
            this.selectedItems = [...newItems];
        },
    },
    mounted() {
        document.addEventListener('mousedown', this.handleClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    },
    methods: {
        toggleMenu() {
            this.showMenu = !this.showMenu;
        },
        toggleSelection(item) {
            const index = this.selectedItems.findIndex(selectedItem => selectedItem.id === item.id);
            if (index !== -1) {
                this.selectedItems.splice(index, 1); 
            } else {
                this.selectedItems.push(item); 
            }
            this.$emit('items-changed', this.selectedItems);
        },
        isSelected(item) {
            return this.selectedItems.some(selectedItem => selectedItem.id === item.id);
        },
        handleClickOutside(event) {
            const itemSelect = this.$refs.itemSelect;
            const itemMenu = this.$refs.itemMenu;
            if (itemSelect && !itemSelect.contains(event.target) && itemMenu && !itemMenu.contains(event.target)) {
                this.showMenu = false; 
            }
        },
    },
};

</script>
  
<style scoped>
.item-select {
    position: relative;
}

.trigger-button {
    padding: 10px;
    border: 1px solid #ccc;
    background-color: white;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border-radius: 5px;
}

.item-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    z-index: 10;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s forwards;
}

.item-list {
    max-height: calc(70vh - var(--header-height));
    overflow-y: auto;
}

.search-bar {
    margin: 10px;
    padding: 10px;
    margin-bottom: 2px;
    width: calc(100% - 20px);
    font-size: small;
    height: 42px;
    border-radius: 6px;
    border: 1px solid #A3A3A3;
}

.item-list-items {
    list-style-type: none;
    padding: 0;
}

.item-item {
    padding: 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.no-items {
    padding: 10px;
    color: #888;
    text-align: center;
}

.selected {
    border-left: 4px solid var(--primary-color);
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translateY(0);
    }

    to {
        opacity: 0;
        transform: translateY(-10px);
    }
}

.fade-leave-active {
    animation: fadeOut 0.3s forwards;
}
</style>
  