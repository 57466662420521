import Vue from 'vue';
import { certificateService } from "../services/certificate.service";

const state = {
  certificates: {}, 
  userCertificates: [], 
  nextPageToken: null,
  pageSize: 25,
  loading: false,
  reloading: false,
  error: null
};

const getters = {
  allCertificates: state => Object.values(state.certificates),
  certificateById: state => id => state.certificates[id],
  userCertificates: state => state.userCertificates,
  isLoading: state => state.loading,
  isReloading: state => state.reloading,
  certificateError: state => state.error
};

const actions = {
  // Fetch all certificates with optional filters
  async fetchCertificates({ commit }, { entityId = null, entityType = null }) {
    commit("setLoading", true);
    try {
      const certificates = await certificateService.fetchCertificates(entityId, entityType);
      commit("setCertificates", certificates);
      return certificates;
    } catch (error) {
      commit("setError", error);
    } finally {
      commit("setLoading", false);
    }
  },

  // Fetch a single certificate by ID
  async fetchCertificateByID({ commit }, id) {
    commit("setLoading", true);
    try {
      const certificate = await certificateService.fetchCertificateByID(id);
      commit("setCertificate", certificate);
      return certificate;
    } catch (error) {
      commit("setError", error);
    } finally {
      commit("setLoading", false);
    }
  },

  // Add a new certificate
  async addCertificate({ commit }, certificateData) {
    commit("setLoading", true);
    try {
      const newCertificate = await certificateService.addCertificate(certificateData);
      commit("setCertificate", newCertificate);
    } catch (error) {
      commit("setError", error);
    } finally {
      commit("setLoading", false);
    }
  },

  // Update an existing certificate
  async updateCertificate({ commit }, certificateData) {
    commit("setLoading", true);
    try {
      const updatedCertificate = await certificateService.updateCertificate(certificateData);
      commit("updateCertificate", updatedCertificate);
    } catch (error) {
      commit("setError", error);
    } finally {
      commit("setLoading", false);
    }
  },

  // Delete a certificate by ID
  async deleteCertificate({ commit }, id) {
    commit("setLoading", true);
    try {
      await certificateService.deleteCertificate(id);
      commit("removeCertificate", id);
    } catch (error) {
      commit("setError", error);
    } finally {
      commit("setLoading", false);
    }
  },

  async fetchCurrentUserCertificates({ commit }) {
    commit("setLoading", true);
    try {
      const certificates = await certificateService.fetchCurrentUserCertificates()
      commit("setUserCertificates", certificates); 
      return certificates;
    } catch (error) {
      commit("setError", error);
    } finally {
      commit("setLoading", false);
    }
  },

  async fetchUserCertificates({ commit, state }, {  entityID = null, entityType = null, searchQuery = null, isNewestFirst = true, reload = false } = {}) {
    if (state.loading) return;
  
    commit("setLoading", true);
    if (reload) commit("setReloading", true);
  
    try {
      const requestParams = { entityID, entityType,searchQuery,
        pageSize: state.pageSize,
        nextPageToken: reload ? null : state.nextPageToken,
        isNewestFirst: isNewestFirst
      };
      
      const responseData = await certificateService.fetchUserCertificates(requestParams);
      const payload = responseData?.payload || [];
      const nextToken = responseData?.nextPageToken || null;
  
      if (reload) {
        commit("setUserCertificates", payload); 
        commit("setReloading", false);
      } else {
        commit("appendUserCertificates", payload); 
      }
  
      commit("setNextPageToken", nextToken);
    } catch (error) {
      commit("setError", error);
      throw error;
    } finally {
      commit("setLoading", false);
    }
  },

  async loadMoreUserCertificates({ dispatch, state }, { entityID = null, entityType = null, isNewestFirst = true, searchQuery = null } = {}) {
    if (!state.nextPageToken || state.loading) return; 
  
    return await dispatch("fetchUserCertificates", { entityID, entityType, searchQuery, isNewestFirst, reload: false });
  },

  async revokeUserCertificate({ commit }, id) {
    commit("setLoading", true);
    try {
      const isRevoked = await certificateService.revokeUserCertificate(id)
      if(isRevoked) commit("removeUserCertificate", id); 
      return isRevoked;
    } catch (error) {
      commit("setError", error);
    } finally {
      commit("setLoading", false);
    }
  },

  async exportUserCertificates({ commit }, {entityId, entityType}) {
    commit("setLoading", true);
    try {
      await certificateService.exportUserCertificates(entityId, entityType)
    } catch (error) {
      commit("setError", error);
    } finally {
      commit("setLoading", false);
    }
  },


  
  
};


const mutations = {
  setCertificates(state, certificates) {
    const certificatesMap = certificates.reduce((acc, certificate) => {
      acc[certificate.id] = certificate;
      return acc;
    }, {});
    Vue.set(state, 'certificates', certificatesMap);
  },

  setCertificate(state, certificate) {
    Vue.set(state.certificates, certificate.id, certificate);
  },

  updateCertificate(state, updatedCertificate) {
    if (state.certificates[updatedCertificate.id]) {
      Vue.set(state.certificates, updatedCertificate.id, updatedCertificate);
    }
  },

  removeCertificate(state, id) {
    Vue.delete(state.certificates, id);
  },

  setUserCertificates(state, userCertificates) {
    Vue.set(state, 'userCertificates', userCertificates);
  },

  removeUserCertificate(state, certificateId) {
    const index = state.userCertificates.findIndex(certificate => certificate.userCertificate.id === certificateId);
    if (index !== -1) {
        state.userCertificates.splice(index, 1);
        Vue.set(state, 'userCertificates', [...state.userCertificates]);
    }
},

  appendUserCertificates(state, certificates) {
    const updatedCertificates = [...state.userCertificates, ...certificates];
    Vue.set(state, 'userCertificates', updatedCertificates);
  },

  setLoading(state, isLoading) {
    Vue.set(state, 'loading', isLoading);
  },

  setReloading(state, isReloading) {
    Vue.set(state, 'reloading', isReloading);
  },

  setNextPageToken(state, token) {
    Vue.set(state, 'nextPageToken', token);
  },

  setError(state, error) {
    Vue.set(state, 'error', error);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
