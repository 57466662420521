<template>
    <button class="create-plan-btn" @click="$emit('open-modal')">
      <img src="@/assets/images/add-border-icon.svg" class="btn-icon" alt="Add" />
      CREATE CERTIFICATE PLAN
    </button>
  </template>
  
  <script>
  export default {
    name: 'CreateCertificateButton',
    emits: ['open-modal']
  }
  </script>
  
  <style scoped>
  .create-plan-btn {
    background-color: #fff;
    border: 1px solid var(--primary-color);
    border-radius: 0.375rem;
    color: #000;
    font-size: 0.875rem;
    font-weight: 500;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .create-plan-btn:hover {
    background-color: #f9fafb;
  }
  
  .btn-icon {
    width: 13px;
    height: 13px;
    margin-left: 0;
  }
  </style>