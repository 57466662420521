export class CertificateModel {
    constructor({
      id = null,
      entityID = null,
      entityType = null,
      certificateName = null,
      certificateType = null,
      description = null,
      defaultValidityPeriodSeconds = null,
      criterias = null
    } = {}) { // Ensure the object passed is always checked before accessing properties
      this.id = id;
      this.entityID = entityID;
      this.entityType = entityType;
      this.certificateName = certificateName;
      this.certificateType = certificateType;
      this.description = description;
      this.defaultValidityPeriodSeconds = defaultValidityPeriodSeconds;
      this.criterias = criterias;
    }
  }
  
  export class CertificateCriteriaModel {
    constructor({
      id = null,
      certificateID = null,
      criteriaType = null,
      criteriaValue = null,
      entityID = null,
      entityType = null
    } = {}) { // Ensure the object passed is always checked before accessing properties
      this.id = id;
      this.certificateID = certificateID;
      this.criteriaType = criteriaType;
      this.criteriaValue = criteriaValue;
      this.entityID = entityID;
      this.entityType = entityType;
    }
  }
  