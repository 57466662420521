<template>
  <div class="certificates-container">

    <div class="search-container">
      <input
        v-model="searchQuery"
        type="text"
        placeholder="Search certificates..."
        class="search-input"
      />
      <i class="fas fa-search search-icon"></i>
    </div>

    <transition-group name="fade" tag="div" class="certificates-grid">
      <div v-if="isLoading" key="loading" class="loading">
        <div class="spinner"></div>
        <p>Loading your achievements...</p>
      </div>

      <div v-else-if="filteredCertificates.length === 0" key="no-results" class="no-results">
        <i class="fas fa-search"></i>
        <p>No certificates found. Try adjusting your search.</p>
      </div>

      <div v-for="cert in filteredCertificates" 
           :key="cert.userCertificate.id" 
           class="certificate-card">
           <div class="ribbon-icon">
            <svg width="60" height="80" viewBox="0 0 60 80" fill="none">
              <path d="M0 0H60V60L30 80L0 60V0Z" fill="var(--primary-color)"/>
              <path d="M30 37.5C35.799 37.5 40.5 32.799 40.5 27C40.5 21.201 35.799 16.5 30 16.5C24.201 16.5 19.5 21.201 19.5 27C19.5 32.799 24.201 37.5 30 37.5Z" stroke="white" stroke-width="3"/>
              <path d="M30 42C23.3726 42 18 47.3726 18 54H42C42 47.3726 36.6274 42 30 42Z" stroke="white" stroke-width="3"/>
            </svg>
          </div>

        <div class="certificate-content">
          <span class="achievement-text">Certificate of {{ cert.certificate.certificateType }}</span>
          <h2 class="certificate-title">{{ cert.certificate.certificateName }}</h2>
          <p class="issuer">{{ getEntityTitle(cert) }}</p>
          
          <div class="certificate-details">
            <span>
              <i class="fas fa-calendar-alt"></i>
              {{ formatDate(cert.userCertificate.issueDate) }}
            </span>
            <span v-if="cert.userCertificate.validUntil">
              <i class="fas fa-hourglass-end"></i>
              {{ formatDate(cert.userCertificate.validUntil) }}
            </span>
          </div>

          <div class="actions">
            <button class="action-btn download-btn" 
                    @click="downloadCertificate(cert.userCertificate.certificateURL)">
              <i class="fas fa-download"></i> Download
            </button>
            <!-- <button class="action-btn share-btn">
              <i class="fas fa-share-alt"></i> Share
            </button> -->
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { EntityType } from "../constants/entity";

export default {
  name: "EnhancedCertificates",
  data() {
    return {
      searchQuery: "",
      certificates: [],
      isLoading: true,
    };
  },
  computed: {
    filteredCertificates() {
      const query = this.searchQuery.toLowerCase();
      return this.certificates.filter(
        (cert) =>
          this.getEntityTitle(cert).toLowerCase().includes(query) ||
          (cert && cert.certificate && cert.certificate.certificateName || "").toLowerCase().includes(query)
      );
    },
  },
  methods: {
    ...mapActions("certificate", ["fetchCurrentUserCertificates"]),
    
    getEntityTitle(cert){ 
      if(cert.certificate.entityType === EntityType.PRODUCTVARIANT){
        return cert.entity.productVariantTitle;
      }
      return cert.entity.title;
    },

    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },

    downloadCertificate(url) {
      window.open(url, '_blank');
    }
  },
  async mounted() {
    try {
      this.certificates = await this.fetchCurrentUserCertificates();
    } catch (error) {
      console.error("Error fetching certificates:", error);
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style scoped>

.certificates-container {
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Roboto', Arial, sans-serif;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 300;
}

.search-container {
  position: relative;
  margin: 2rem 0;
}

.search-input {
  width: 100%;
  padding: 1rem 1rem 1rem 3rem;
  font-size: 1rem;
  border: 2px solid var(--primary-color);
  border-radius: 1rem;
  outline: none;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.search-input:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(67, 97, 238, 0.3);
}

.search-icon {
  position: absolute;
  width: min-content;
  left: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--primary-color);
}

.certificates-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  padding: 1rem 0;
}

.certificate-card {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  position: relative;
  transition: all 0.3s ease;
  overflow: hidden;
}

.certificate-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.ribbon-icon {
  position: relative;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-around;
}

.certificate-content {
  position: relative;
  z-index: 2;
  margin-top: 2rem;
  text-align: center;
}

.achievement-text {
  color: #666;
  font-size: 0.9rem;
  display: block;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.certificate-title {
  font-size: 1.4rem;
  color: #333;
  margin: 0.5rem 0;
  line-height: 1.4;
  font-weight: 600;
}

.issuer {
  color: #666;
  font-size: 1rem;
  margin: 0.5rem 0 1.5rem;
  font-style: italic;
}

.certificate-details {
  display: flex;
  justify-content: center;
  gap: 1rem;
  font-size: 0.9rem;
  color: #666;
  margin: 1rem 0;
}

.certificate-details i {
  margin-right: 0.5rem;
  color: var(--primary-color);
}

.actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1.5rem;
}

.action-btn {
  background: none;
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-btn i {
  margin-right: 0.5rem;
}

.action-btn:hover {
  background-color: var(--primary-color);
  color: white;
}

.download-btn {
  background-color: var(--primary-color);
  color: white;
}

.download-btn:hover {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.loading {
  text-align: center;
  margin: 4rem 0;
  grid-column: 1 / -1;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: var(--primary-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto 1rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.no-results {
  text-align: center;
  margin: 4rem 0;
  color: #666;
  grid-column: 1 / -1;
}

.no-results i {
  font-size: 3rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

@media (max-width: 768px) {
  .certificates-container {
    padding: 1rem;
  }
  
  .title {
    font-size: 2rem;
  }

  .certificates-grid {
    grid-template-columns: 1fr;
  }

  .certificate-details {
    flex-direction: column;
    align-items: center;
  }

  .actions {
    flex-direction: column;
  }

  .action-btn {
    width: 100%;
  }
}
</style>