<template>
  <div class="certificate-content">
    <div class="header-actions">
      <CreateCertificateButton @open-modal="showModal = true" />
    </div>

    <div class="search-container">
      <input type="text" placeholder="Search certificates by name " v-model="searchQuery" class="search-input">
    </div>

    <div class="table-container">
      <div v-if="loading" class="loading-container">
        <div class="spinner"></div>
        <p class="loading-text">Loading certificates, please wait...</p>
      </div>

      <div v-else-if="!loading && filteredCertificates.length === 0" class="no-data-container">
        <p class="no-data-text">No certificates found. Try adjusting your search or creating a new certificate.</p>
      </div>


      <table v-else-if="!loading && filteredCertificates.length > 0">
        <thead>
          <tr>
            <th class="checkbox-column">
              <input type="checkbox" v-model="selectAll" @change="toggleSelectAll">
            </th>
            <th>Certificate Name</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(certificate, index) in filteredCertificates" :key="index">
            <td class="checkbox-column">
              <input type="checkbox" v-model="certificate.selected">
            </td>
            <td>{{ certificate.certificateName }}</td>
            <td>{{ certificate.updatedAt || certificate.createdAt }}</td>
            <td>
              <div class="actions-dropdown" :ref="'actions' + certificate.id">
                <button class="actions-btn" @click="toggleActionMenu(certificate.id)">
                  ACTIONS
                  <i class="fas fa-chevron-down" />
                </button>
                <div v-if="activeActionMenu === certificate.id" class="custom-dropdown-menu">
                  <button class="action-option" @click="editCertificate(certificate)">
                    <i class="fas fa-edit" />
                    Edit
                  </button>
                  <button class="action-option text-red-500" @click="deleteCertificateByID(certificate.id)">
                    <i class="fas fa-trash" />
                    Delete
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <ManageCertificateModal :visible="showModal" @updateModalState="toggleManageModal"
      :certificateData="selectedCertificateData" :isEditMode="isEditing" />
  </div>
</template>

<script>
import CreateCertificateButton from './CreateCertificateButton.vue'
import ManageCertificateModal from './ManageCertificateModal.vue'
import { mapActions, mapState } from 'vuex'
import { EntityType } from '../../../constants/entity';

export default {
  name: 'CertificateManagement',
  components: { CreateCertificateButton, ManageCertificateModal, EntityType },
  data() {
    return {
      searchQuery: '',
      selectAll: false,
      showModal: false,
      loading: false,
      activeActionMenu: null,
      isEditing: false,
      selectedCertificateData: null,
    }
  },
  computed: {
    ...mapState("certificate", ["certificates"]),

    certificateList(){
     return Object.values(this.certificates || {});
    },

    filteredCertificates() {
      return this.certificateList.filter(certificate =>
        certificate.certificateName.toLowerCase().includes(this.searchQuery.toLowerCase())
      )
    }
  },
  methods: {
    ...mapActions("certificate", ["fetchCertificates", "deleteCertificate"]),

    toggleManageModal(state) {
      if (state !== undefined) {
        this.showModal = state;
      } else {
        this.showModal = !this.showModal;
      }

      if (!state) {
        this.selectedCertificateData = null;
        this.isEditing = false;
      }
    },

    async getCertificates() {
      try {
        this.loading = true;
        const productVariantId = this.$route.params.id;
        await this.fetchCertificates({ entityId: productVariantId, entityType: EntityType.PRODUCTVARIANT });
      }
      catch (e) { }
      finally { this.loading = false; }

    },
    toggleSelectAll() {
      this.certificateList.forEach(certificate => certificate.selected = this.selectAll)
    },
    toggleActionMenu(id) {
      this.activeActionMenu = this.activeActionMenu === id ? null : id
    },
    editCertificate(certificate) {
      this.selectedCertificateData = certificate;
      this.isEditing = true;
      this.showModal = true;
    },
    async deleteCertificateByID(id) {
      await this.deleteCertificate(id);
    }
  },
  async mounted() {
    this.getCertificates()
  },
}
</script>

<style scoped>
.certificate-content {
  height: calc(100svh - var(--header-height) - 65px);
  padding: 2rem;
  overflow: auto;
}

.header-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.5rem;
}

.search-container {
  margin-bottom: 1.5rem;
}

.search-input {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  font-size: 0.875rem;
}

.table-container {
  margin-bottom: 1.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid #e5e7eb;
}

th {
  background-color: #f9fafb;
  font-weight: 500;
  color: #374151;
}

.checkbox-column {
  width: 48px;
}

.actions-btn {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  background-color: white;
  font-size: 0.875rem;
  cursor: pointer;
}

.actions-dropdown {
  position: relative;
}

.custom-dropdown-menu {
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: 0.25rem;
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 999;
  min-width: 120px;
  overflow: visible;
}

.dropdown-arrow {
  font-size: 0.625rem;
}

.action-option {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  color: #374151;
  width: 100%;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
}

.action-option:hover {
  background-color: #f9fafb;
}

.text-red-500 {
  color: #ef4444;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: #555;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top-color: black;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.loading-text {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
}

.no-data-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: #333;
  text-align: center;
  margin-top: 10px;
}

.no-data-icon {
  font-size: 40px;
  margin-bottom: 10px;
}

.no-data-text {
  font-size: 16px;
  font-weight: 500;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

</style>
